import { useLanguage } from '@/hooks/Contexts'
import { Format } from '@/utils/Format'
import { svgs } from '@/utils/Images'
import { Action0, Action1 } from '@/utils/Types'
import { useRouter } from 'next/router'
import { PropsWithChildren, useEffect, useState } from 'react'
import ReactPlayer from 'react-player/lazy'

export function InformationLayout(props: PropsWithChildren<{ show?: boolean; additionalClasses?: string; title?: string }>) {
  const [show, toggleShow] = useState<boolean>(false)

  const toggle = (): void => {
    toggleShow(!show)
  }

  useEffect(() => {
    if (props.show && !show) {
      toggleShow(true)
    }
  }, [props.show])

  return (
    <div className={`my-3 w-full self-start overflow-hidden bg-grey3 p-2 transition-all duration-300 md:p-4 ${props.additionalClasses ?? ''}`}>
      <div className='flex w-full cursor-pointer justify-between font-semibold hover:text-blue-rgba1' onClick={toggle}>
        {props.title}
        <button aria-label='Toggle display'>
          <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            {show ? svgs.minus : svgs.plus}
          </svg>
        </button>
      </div>
      <div className={`flex animate-fade-in-down flex-col py-2 text-sm ${show ? 'block' : 'hidden'}`}>{props.children}</div>
    </div>
  )
}

export function PageHeader(props: any) {
  return (
    <div className={`page-title capitalize ${props.additionalClasses ?? ''}`}>
      <span className='main-container'>{props.title}</span>
    </div>
  )
}

export function TotalLayout(props: { name: string; value: number; isDiscount?: boolean; additionClasses?: string }) {
  return (
    <div className={`flex justify-between text-sm ${props.additionClasses ?? ''} ${props.isDiscount === true ? 'text-xs text-error' : ''}`}>
      <span>{props.name}</span>
      <span>
        {props.isDiscount && '-'}
        {Format.withCurrency(props.value)}
      </span>
    </div>
  )
}

const FB_APP = process.env.NEXT_PUBLIC_FACEBOOK_APP_ID
interface VideoDrawPlayerProps {
  url?: string
  iframe?: string
  additionalClasses?: string
}

export function VideoDrawPlayer(props: VideoDrawPlayerProps) {
  const { url, iframe, additionalClasses } = props
  return url ? (
    <iframe
      title={url}
      src={url + `&show_text=false&appId=${FB_APP}`}
      loading='lazy'
      scrolling='no'
      frameBorder={0}
      allowFullScreen={true}
      allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'
      className={`aspect-video w-full cursor-pointer ${additionalClasses ?? ''}`}
    />
  ) : iframe ? (
    <div dangerouslySetInnerHTML={{ __html: iframe }} />
  ) : null
}

export const ModalVideoDrawPlayer = function ({
  url,
  iframe,
  additionalClasses,
  isPlaying,
  toggleIsPlaying,
  toggleIsPlayerIFrame,
}: {
  isPlayerIFrame?: boolean
  isPlaying?: boolean
  toggleIsPlaying?: Action1<boolean>
  toggleIsPlayerIFrame?: Action1<boolean>
} & VideoDrawPlayerProps) {
  const [isVideoPlaying, setIsVideoPlaying] = useState(false)
  const modifiedUrl = url ? new URL(decodeURIComponent(url)).searchParams.get('href') : ''

  useEffect(() => {
    toggleIsPlayerIFrame(!ReactPlayer.canPlay(modifiedUrl))
  }, [url])

  useEffect(() => {
    setIsVideoPlaying(isPlaying)
  }, [isPlaying])

  return ReactPlayer.canPlay(modifiedUrl) ? (
    <ReactPlayer
      className={`aspect-video w-full cursor-pointer ${additionalClasses ?? ''}`}
      controls={true}
      pip={true}
      stopOnUnmount={false}
      url={modifiedUrl}
      playing={isVideoPlaying}
      width='100%'
      height='100%'
      config={{
        facebook: {
          appId: `${FB_APP}`,
          attributes: {
            'data-show-text': false,
          },
        },
      }}
      onPlay={() => {
        toggleIsPlaying(true)
        setIsVideoPlaying(true)
      }}
    />
  ) : url ? (
    //returns videodrawplayer if the url cannot be played in ReactPlayer
    // isPlayerIFrame is set to true
    <VideoDrawPlayer url={url} iframe={iframe} />
  ) : null
}

export function TextBack(props: { text: string; goBack?: Action0 }) {
  const router = useRouter()
  return (
    <div
      onClick={() => {
        if (props.goBack) {
          props.goBack()
        } else {
          router.back()
        }
      }}
      className='flex cursor-pointer flex-row text-xs text-black underline hover:text-yellow-hex2 md:text-sm'
    >
      {'< ' + props.text}
    </div>
  )
}

export function NoDataLayout(props: { additionalClasses?: string; content?: string }) {
  const { errors } = useLanguage()
  return <div className={`w-full justify-center py-4 text-center ${props.additionalClasses ?? ''}`}>{props.content ? props.content : errors.no_data}</div>
}
