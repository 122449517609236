import { MexplayModalContent } from '@/components/MexplayModal'
import {
  useCompetitionShortNameContext,
  useLanguage,
  useLayout,
  useLocaleContext,
  useMyCart,
  useShowCartContext,
  useShowMexplayModalContext,
  useSystemConfig,
  useUser,
} from '@/hooks/Contexts'
import useWindowDimensions from '@/hooks/Index'
import { API_URL } from '@/utils/Consts'
import { Format } from '@/utils/Format'
import { svgs } from '@/utils/Images'
import { Action0, Action1 } from '@/utils/Types'
import { CartDetail, CartItem, UserBalance } from '@models/Index'
import { appUtils } from 'lib/AppUtils'
import axios from 'lib/axios'
import { useRouter } from 'next/router'
import { ChangeEvent, FC, useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useSWRConfig } from 'swr'
import AddressLayout from './Address'
import { ButtonWithLoading } from './Button'
import { CountDown } from './CountDown'
import { Divide, LoadingView } from './FormElement'
import { ExpandableBlock, TextInput } from './FormElementV2'
import { ImgFallback } from './Image'
import { RecommendCompetitions } from './Layouts/Competition'
import { TotalLayout } from './Layouts/Index'

interface UserCartProps {
  showCart: boolean
  toggleShowCart: Action0
}

export const UserCartButton = () => {
  const cartCount = useMyCart()
  const { showCart, setShowCart } = useShowCartContext()
  const { setShowMexplayModal } = useShowMexplayModalContext()
  const { shortName, setShortName } = useCompetitionShortNameContext()
  const { isDesktop } = useLayout()
  return (
    <>
      <div
        className={`fixed bottom-36 right-4 z-[700] 2xl:right-[10%] ${!showCart ? 'opacity-100' : ' pointer-events-none opacity-0'} ${
          !isDesktop && shortName ? '!pointer-events-none !opacity-0' : ''
        } `}
      >
        <div className='relative flex h-[48px] w-[48px] justify-center rounded-full bg-blue-rgba8'>
          <button
            onClick={() => {
              if (!appUtils.isEmpty(shortName)) {
                setShortName('')
              } else {
                setShowMexplayModal(prev => !prev)
              }
              setShowCart(prev => !prev)
              // @ts-ignore
              if (document.fullscreenElement || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement) {
                // @ts-ignore
                if (window.safari !== undefined) {
                  // @ts-ignore
                  document?.webkitExitFullscreen()
                } else {
                  document?.exitFullscreen()
                }
              }
            }}
            className='flex w-full items-center justify-center'
          >
            <svg className='h-8 w-8 cursor-pointer items-center text-white hover:text-blue-rgba1' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
              {svgs.cart}
            </svg>
          </button>

          {cartCount && (
            <>
              <span className='absolute -right-1 rounded-full border border-solid border-yellow-hex2 bg-yellow-hex2 px-1 text-xs font-medium'>
                {cartCount.items}
              </span>
            </>
          )}
        </div>
      </div>
    </>
  )
}

export const ModalCartDetails: FC<UserCartProps> = ({ showCart, toggleShowCart }) => {
  const router = useRouter()
  const user = useUser()
  const myCart = useMyCart()
  const { locale } = useLocaleContext()
  const cartContainerRef = useRef(null)
  const { isDesktop } = useLayout()
  const { height: screenHeight } = useWindowDimensions()
  const { screen } = useSystemConfig()
  const { errors: errStr, cart: cartStr, auth: authStr } = useLanguage()

  const [dropDownOpen, setDropDownOpen] = useState(false)
  const [error, setError] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [paying, setPaying] = useState(false)
  const { mutate } = useSWRConfig()
  const [hasOrder, setHasOrder] = useState(false)
  const [balance, setBalance] = useState<UserBalance>(null)
  const [cartDetail, setCartDetail] = useState(null as CartDetail)
  const [showTransactSuccess, setShowTransactSuccess] = useState(false)
  const [newOrderID, setNewOrderID] = useState(0)
  const [couponData, setCouponData] = useState<{
    code: string
    needValid: boolean
    valid: boolean
    applying: boolean
    message: string
  }>({
    code: '',
    needValid: false,
    valid: false,
    applying: false,
    message: '',
  })
  const formInstance = useForm({
    mode: 'onBlur',
  })
  const {
    handleSubmit,
    trigger,
    reset,
    getValues,
    formState: { errors },
  } = formInstance

  const toggleDropDownOpen = (isOpen: boolean) => {
    setDropDownOpen(isOpen)
  }

  const updateCartLocal = cartData => {
    let countData = {
      id: 0,
      items: 0,
      grant_total: 0,
    }
    if (cartData && !Array.isArray(cartData)) {
      countData = {
        id: cartData.id,
        items: cartData.total_items_amount,
        grant_total: cartData.grand_total,
      }
    }
    mutate(API_URL.cartCount, { ...countData }, false)
  }

  // Needs to revalidate when there is an error
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name } = e.target
    if (errors[name]) {
      trigger(name)
    }
  }
  const fetchBalance = () => {
    //fetch balance from mexplay input here
    appUtils.fetcher(API_URL.getUserBalance, false, locale).then(res => {
      if (res) setBalance(res)
    })
  }

  const handleCartChanged = e => {
    if (e.response && e.response.data) {
      const result = e.response.data.data
      setCartDetail(result)
      updateCartLocal(result)

      if (!appUtils.isEmpty(result) && result.updated) {
        toast.error(appUtils.getInventoryAlert(result.updated, cartStr))
      } else {
        toast.error(appUtils.handleApiError(e, errStr))
      }
    } else {
      toast.error(appUtils.handleApiError(e, errStr))
    }
  }

  const onSubmit = data => {
    if (!user) {
      toast.warning(errStr.login_to_order)
      appUtils.postNeedMessage('loginNeed')
    } else {
      setPaying(true)
      axios
        .post(API_URL.placeOrder, { cart_id: cartDetail.id, address: data.address, payment: { gateway: 'gmag' }, screen: screen })
        .then(res => {
          if (res && res.data) {
            const resultCode = res.data.code
            const result = res.data.data
            setPaying(false)
            if (resultCode == '0') {
              setShowTransactSuccess(true)
              setNewOrderID(result.id)
              setPaying(false)
              setCartDetail(null)
              return
            } else {
              toast.error(appUtils.getErrorMsgByCode(resultCode, errStr))
            }
          }
        })
        .catch(e => {
          setPaying(false)
          handleCartChanged(e)
        })
    }
  }

  useEffect(() => {
    if (error) {
      const errorMsg = appUtils.isEmpty(error.message) ? appUtils.getErrorMsgByCode(error.toString(), errStr) : error.message.toString()
      toast.error(errorMsg)
    }
  }, [error])

  useEffect(() => {
    if (!showCart) {
      setError(null)
      setPaying(false)
      setHasOrder(false)
      setBalance(null)
      setShowTransactSuccess(false)
      setNewOrderID(0)
      setCouponData({
        code: '',
        needValid: false,
        valid: false,
        applying: false,
        message: '',
      })
      return
    }
    setIsLoading(true)
    const fetchCartDetail = async () => {
      await axios
        .get(API_URL.cartDetail + '?store_id=2', {
          headers: {
            'Accept-Language': locale,
          },
        })
        .then(res => {
          if (res && res.data && res.data.code == '0') {
            setCartDetail(res.data.data)
            updateCartLocal(res.data.data)
          }
          setIsLoading(false)
        })
        .catch(e => {
          setIsLoading(false)
          setError(e)
        })
    }

    fetchCartDetail()
  }, [showCart])

  useEffect(() => {
    if (cartDetail && !appUtils.isEmpty(cartDetail)) {
      setHasOrder(cartDetail.has_order)
      if (cartDetail.coupon_code) {
        setCouponData({ ...couponData, code: cartDetail.coupon_code, needValid: true, valid: true })
      } else {
        setCouponData({ ...couponData, code: '', needValid: false, message: '' })
      }
      const defaultValues = getValues()
      if (cartDetail.billing_address) {
        reset({ ...defaultValues, address: cartDetail.billing_address })
      } else if (user && !defaultValues?.address?.first_name) {
        appUtils.fetcher(API_URL.getUserAddresses, true, locale).then(res => {
          if (res && res.length > 0) {
            const firstAddress = res[0]
            if (!firstAddress.email && !appUtils.isFakeEmail(user.email)) {
              firstAddress.email = user.email
            }
            reset({ ...defaultValues, address: firstAddress })
          }
        })
      }
      if (user) {
        fetchBalance()
      }
    }
  }, [cartDetail])

  useEffect(() => {
    if (myCart && !appUtils.isEmpty(cartDetail)) {
      if (!myCart.id) {
        setCartDetail(null)
      } else if (myCart.grant_total != cartDetail.grand_total) {
        router.reload()
      }
    }
  }, [myCart])

  return (
    <MexplayModalContent
      innerClasses={`container md:h-max h-full ${showCart ? 'animate-fade-in-down opacity-100' : ' animate-fade-out-up opacity-0 pointer-events-none'}`}
      childrenContainerClasses={`animate-fade-in-down`}
    >
      <LoadingView fixed={true} show={isLoading || paying} />
      <div
        ref={cartContainerRef}
        className={`flex max-h-screen w-full flex-col overflow-y-scroll rounded-lg lg:h-max ${
          isDesktop && dropDownOpen && cartContainerRef?.current?.clientHeight < screenHeight ? 'md:!overflow-y-visible' : ''
        }`}
      >
        <div className='sticky top-0 z-20 flex w-full justify-between bg-blue-rgba2 py-4 px-2 md:px-4'>
          <span className='mr-4 text-base font-semibold md:text-lg'>{cartStr.my_cart}</span>

          <svg
            className='-mt-1 h-8 w-8 cursor-pointer rounded border border-blue-rgba6 hover:text-blue-rgba1'
            viewBox='0 0 24 24'
            fill='currentColor'
            onClick={() => {
              if (toggleShowCart) {
                toggleShowCart()
              }
            }}
          >
            {svgs.cross}
          </svg>
        </div>
        <div className='mb-4 flex w-full justify-center px-2 sm:px-4'>
          {!showTransactSuccess ? (
            <div className='w-full'>
              {cartDetail && !Array.isArray(cartDetail) && cartDetail.items && cartDetail.items.length > 0 ? (
                <>
                  {hasOrder && cartDetail.p_time && (
                    <div className='-mt-2 flex w-full flex-col items-center justify-center rounded p-2 text-sm text-error md:mb-2 md:flex-row md:text-base'>
                      <div className='mr-2 flex items-center'>
                        <svg className='mr-1 h-4 w-4' viewBox='0 0 20 20' fill='currentColor'>
                          {svgs.clock}
                        </svg>
                        {cartStr.order_reserved}
                      </div>
                      <CountDown
                        date={cartDetail.p_time}
                        ms={true}
                        additionalClasses='text-sm md:text-base font-semibold'
                        onFinish={() => {
                          mutate(API_URL.cartCount)
                        }}
                      />
                    </div>
                  )}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='flex w-full flex-col lg:flex-row'>
                      <div className='basis-2/3 pb-4 lg:pb-0 lg:pr-4'>
                        <Divide additionalClasses='!bg-blue-rgba6' />
                        {cartDetail.items &&
                          cartDetail.items.map((item, index) => {
                            return (
                              <CartItemRow
                                item={item}
                                key={index}
                                totalItems={cartDetail.items.length}
                                cartId={cartDetail.id}
                                hasOrder={hasOrder}
                                onChange={res => {
                                  setCartDetail(res)
                                  updateCartLocal(res)
                                }}
                              />
                            )
                          })}

                        {user && (
                          <div className='mt-4'>
                            <AddressLayout
                              openDefault={true}
                              onChange={onChange}
                              form={formInstance}
                              title={cartStr.billing_address}
                              toggleDropDownOpen={toggleDropDownOpen}
                            />
                          </div>
                        )}
                      </div>
                      <div className='basis-1/3 md:pb-0'>
                        {user && (!hasOrder || (hasOrder && cartDetail.coupon_code)) && (
                          <ExpandableBlock title={cartStr.discount_codes} openDefault={true} additionalClasses='mb-4 md:mt-0'>
                            <div className='my-2 mt-4 md:my-0'>
                              <div className='flex w-full justify-between'>
                                <div className='flex-1'>
                                  <TextInput
                                    label={cartStr.enter_coupon_code}
                                    showValidated={couponData.needValid}
                                    valid={couponData.valid}
                                    input={{
                                      name: 'couponCode',
                                      value: couponData.code,
                                      onChange: event => {
                                        setCouponData({ ...couponData, code: event.currentTarget.value, needValid: false, message: '' })
                                      },
                                      disabled: couponData.applying || (couponData.needValid && couponData.valid),
                                    }}
                                  />
                                </div>
                                <ButtonWithLoading
                                  title={cartStr.apply}
                                  disabled={!couponData.code || couponData.applying || (couponData.needValid && couponData.valid)}
                                  isLoading={couponData.applying}
                                  className='btn-white ml-4 px-6 md:px-8'
                                  onClick={() => {
                                    if (!couponData.code || couponData.applying) {
                                      return
                                    }
                                    appUtils
                                      .postData(
                                        API_URL.addCoupon,
                                        { coupon_code: couponData.code, cart_id: cartDetail.id },
                                        locale,
                                        errStr,
                                        false,
                                        () => {
                                          setCouponData({ ...couponData, applying: true })
                                        },
                                        msg => {
                                          setCouponData({ ...couponData, needValid: true, valid: false, message: msg })
                                        }
                                      )
                                      .then(res => {
                                        if (res) {
                                          setCouponData({ ...couponData, needValid: true, valid: true, message: cartStr.apply_success })
                                          setCartDetail(res)
                                        }
                                      })
                                  }}
                                />
                              </div>
                              {couponData.message && (
                                <div className={`mt-2 text-sm ${couponData.valid ? 'text-success' : 'text-error'}`}>{couponData.message}</div>
                              )}
                            </div>
                          </ExpandableBlock>
                        )}
                        <div className='sticky top-16 z-10 '>
                          <BillingSummary cartDetail={cartDetail} balance={balance} paying={paying} />
                          {!user && (
                            <div className='mt-4 flex w-full flex-col'>
                              <span className='flex w-full items-center justify-center text-sm text-warning'>
                                <svg className='mr-2 h-6 w-6 text-white' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                                  {svgs.user}
                                </svg>
                                {errStr.login_to_order}
                              </span>
                              <button
                                type='button'
                                className='btn-primary mx-4 mt-4 w-2/3 self-center py-3'
                                onClick={() => {
                                  toggleShowCart()
                                  appUtils.postNeedMessage('loginNeed')
                                }}
                              >
                                {authStr.login + ' / ' + authStr.register}
                              </button>
                            </div>
                          )}
                          {user && balance && balance.available_amount < cartDetail.grand_total && (
                            <div className='mt-4 flex w-full flex-col'>
                              <span className='flex w-full items-center justify-center px-4 text-center text-sm text-warning'>
                                {errStr.not_enoug_monney_tip}
                              </span>
                              <button
                                type='button'
                                className='btn-primary mx-4 mt-4 w-2/3 self-center py-3'
                                onClick={() => {
                                  toggleShowCart()
                                  appUtils.postNeedMessage('depositNeed')
                                }}
                              >
                                {cartStr.deposit}
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </form>
                </>
              ) : (
                !isLoading && (appUtils.isEmpty(cartDetail) || cartDetail.items?.length == 0) && <CartEmpty toggleShowCart={toggleShowCart} />
              )}
            </div>
          ) : (
            <TransactionSuccessful toggleShowCart={toggleShowCart} newOrderID={newOrderID} />
          )}
        </div>
      </div>
    </MexplayModalContent>
  )
}

interface CartItemRowProps {
  item: CartItem
  cartId: number
  totalItems: number
  hasOrder: boolean
  onChange: Action1<CartDetail>
}
export const CartItemRow: FC<CartItemRowProps> = ({ item, cartId, totalItems, hasOrder, onChange }) => {
  const { locale } = useLocaleContext()
  const [qty, setQty] = useState(0)
  const [updating, setUpdating] = useState(false)
  const ticketLimit = Math.min(item.user_tickets_left ?? item.user_limit_amount, item.tickets_left)
  const { errors: errStr, cart: cartStr } = useLanguage()

  useEffect(() => {
    setQty(item.qty)
  }, [item.qty])

  const remove = () => {
    appUtils
      .postData(
        totalItems == 1 ? API_URL.clearCart : API_URL.updateCart,
        {
          cart_id: cartId,
          item: {
            id: item.id,
            qty: 0,
          },
        },
        locale,
        errStr,
        true,
        setUpdating
      )
      .then(res => {
        if (res) {
          toast.success(cartStr.item_remove_success)
          onChange(res)
        }
      })
  }
  const update = changed => {
    appUtils
      .postData(
        API_URL.updateCart,
        {
          cart_id: cartId,
          item: {
            id: item.id,
            qty: changed,
          },
        },
        locale,
        errStr,
        false,
        setUpdating,
        str => {
          toast.error(str)
        }
      )
      .then(res => {
        if (res) {
          onChange(res)
          setQty(changed)
          toast.success(cartStr.update_success, { toastId: cartId })
        } else {
          // router.reload()
        }
      })
  }

  const title = () => {
    return (
      <>
        <span className='text-sm font-semibold md:text-base'>{item.title ? item.title : item.short_name}</span>
        <button onClick={remove} className={`ml-4  ${hasOrder ? 'hidden' : 'flex'}`} type='button'>
          <svg viewBox='0 0 23 27' fill='none' xmlns='http://www.w3.org/2000/svg' className='h-4 w-4 hover:text-blue-rgba1'>
            {svgs.mexplay_icons.trash_bin}
          </svg>
        </button>
      </>
    )
  }

  const ItemCountButtons = () => {
    const btnClass = 'p-1 text-lg border border-grey rounded hover:bg-blue-rgba6 disabled:text-grey disabled:hover:bg-transparent disabled:cursor-not-allowed'
    return (
      <div className='flex w-fit flex-row items-center justify-center rounded'>
        <button
          aria-label='Decrease number'
          className={`${btnClass} ${hasOrder ? 'hidden' : 'block'}`}
          disabled={qty == 1 || updating}
          onClick={event => {
            event.preventDefault()
            if (qty > 1) {
              if (ticketLimit < qty - 1) {
                // 先加入购物车，再登录的情况，做的修改，
                toast.warning(errStr.limit_reach)
              }
              update(Math.min(qty - 1, ticketLimit))
            }
          }}
        >
          <svg className='h-4 w-4' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            {svgs.minus}
          </svg>
        </button>
        <span className={!hasOrder ? 'px-3 text-base font-medium md:px-4' : ''}>{hasOrder ? 'x' + qty : qty}</span>

        {/* <span className={!hasOrder ? 'flex h-full px-4 text-center text-base font-medium' : ''}>{hasOrder ? 'x' + qty : qty}</span> */}
        <button
          aria-label='Increase number'
          className={`${btnClass} ${hasOrder ? 'hidden' : 'block'}`}
          onClick={event => {
            event.preventDefault()
            if (ticketLimit <= qty) {
              return
            }
            update(Number.parseInt(qty.toString()) + 1)
          }}
          disabled={updating || ticketLimit <= qty}
        >
          <svg className='h-4 w-4' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
            {svgs.plus}
          </svg>
        </button>
      </div>
    )
  }

  return (
    <div className='mt-4 flex flex-col'>
      <div className='flex flex-row'>
        <ImgFallback src={item.thumbnail} alt={item.thumbnail} className='mr-2 h-full w-32 rounded object-cover md:mr-4 md:block md:w-36' />
        <div className='flex flex-1 flex-col justify-between'>
          <div className='flex justify-between'>{title()}</div>
          <div className='flex flex-col text-sm'>
            <div className='mt-1'>
              <span className='mr-2 text-grey'>{cartStr.price}:&nbsp;</span>
              {Format.withCurrency(item.price)}
              {item.normal_price && <span className='ml-2 text-sm text-error line-through'>{Format.withCurrency(item.normal_price)}</span>}
            </div>
            <div className='mt-1 flex justify-between md:mt-4'>
              <ItemCountButtons />
              <div className='relative w-full text-right font-semibold'>
                <span className='absolute -bottom-1 right-0 text-sm md:text-base'>{Format.withCurrency(item.row_total)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Divide additionalClasses='!bg-blue-rgba6 mt-4' />
    </div>
  )
}

interface TransactionSuccessfulProps {
  toggleShowCart: Action0
  newOrderID: number
}

const TransactionSuccessful: FC<TransactionSuccessfulProps> = ({ toggleShowCart, newOrderID }) => {
  const { orders: orderStr } = useLanguage()
  const router = useRouter()
  return (
    <div className='flex animate-fade-in-down flex-col'>
      <div className='border-t border-b border-blue-rgba6'>
        <div className='flex w-full flex-col py-6 text-center'>
          <span className='font-bold text-blue-rgba1 drop-shadow-s1'>{orderStr.check_junk_email}</span>
          <span className='mt-2 text-lg md:mt-4'>{orderStr.thank_you_for_order}</span>
        </div>
      </div>
      <div className='mb-4 flex flex-col items-center rounded-lg drop-shadow-s1'>
        <svg
          width='182'
          height='182'
          viewBox='0 0 182 182'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          className='h-40 w-40 py-4 text-success drop-shadow-s1'
        >
          {svgs.mexplay_icons.transact_success_check}
        </svg>
        <div className='py-4 text-center'>
          <span className='text-lg drop-shadow-s1 md:text-xl'>{orderStr.transaction_successful}</span>
        </div>
        <div className='py-2'>
          <button
            type='button'
            onClick={() => {
              toggleShowCart()
              router.push('/user/orders/' + newOrderID)
            }}
          >
            <span className='font-bold text-blue-rgba1 underline drop-shadow-s1'>{orderStr.view_order}</span>
          </button>
        </div>
        {/* <button
          type='button'
          className='py-2'
          onClick={() => {
            toggleShowCart()
            router.push('/')
          }}
        >
          <span className='text-blue-rgba1 underline'>{orderStr.check_more_deals}</span>
        </button> */}
        <RecommendCompetitions toggleParentModal={toggleShowCart} />
      </div>
    </div>
  )
}

interface BillingSummaryProps {
  cartDetail: CartDetail
  balance: UserBalance
  paying: boolean
}

const BillingSummary: FC<BillingSummaryProps> = ({ cartDetail, paying, balance }) => {
  const { cart: cartStr, wallet: walletStr } = useLanguage()
  const user = useUser()
  // const [agree, setAgree] = useState(true)
  return (
    <div className='mt-4 rounded bg-grey6 px-2 py-4 md:mt-0 md:px-4'>
      <div className='mb-4 flex justify-between text-base font-semibold'>
        {balance ? (
          <span>
            {walletStr.balance}:<span className='ml-2 text-success'>{Format.withCurrency(balance.available_amount)}</span>
          </span>
        ) : (
          <span>{cartStr.billing_summary}</span>
        )}
      </div>
      <Divide additionalClasses='!bg-grey13 my-4' />
      <TotalLayout name={cartStr.subtotal} value={cartDetail.subtotal} />

      {cartDetail.discount_rules && Array.isArray(cartDetail.discount_rules)
        ? cartDetail.discount_rules.map((item, index) => <TotalLayout key={index} name={item.name} value={item.amount} isDiscount additionClasses='mt-1' />)
        : null}

      {cartDetail.coupon_code && cartDetail.coupon_name && (
        <TotalLayout name={cartDetail.coupon_name} value={cartDetail.coupon_amount} isDiscount additionClasses='mt-1' />
      )}

      <TotalLayout name={cartStr.grand_total} value={cartDetail.grand_total} additionClasses='mt-2 !text-base font-semibold' />
      <Divide additionalClasses='!bg-grey13 mt-4' />
      {/* <div className='my-4 flex text-sm'>
        <Checkbox
          checked={agree}
          onChange={() => {
            setAgree(!agree)
          }}
          additionalClasses='h-max mt-3'
        />
        <span className='ml-2'>
          {cartStr.acknowledge}
          <a href='https://mexplay.mx/privacy-policy' target='_blank' rel='noopener noreferrer'>
            <span className='cursor-pointer text-blue-rgba1'>{cartStr.privacy_and_terms_policy}</span>
          </a>
        </span>
      </div> */}
      {user && balance && balance.available_amount >= cartDetail.grand_total && (
        <ButtonWithLoading
          title={cartStr.pay + ' ' + Format.withCurrency(cartDetail.grand_total)}
          isLoading={paying}
          disabled={paying}
          type='submit'
          className='btn-primary'
          additionalClasses='w-full py-3 mt-4 mb-32 md:mb-0'
        />
      )}
    </div>
  )
}
interface CartEmptyProps {
  toggleShowCart: Action0
}
const CartEmpty: FC<CartEmptyProps> = ({ toggleShowCart }) => {
  const { cart: cartStr } = useLanguage()
  const router = useRouter()
  return (
    <div className='flex animate-fade-in-down flex-col items-center justify-center pb-4 md:pb-0'>
      <div className='w-full border-t border-b border-blue-rgba6 py-4 text-center'>
        <span className='text-xl'>{cartStr.cart_empty}</span>
      </div>
      <button
        className='btn-white my-6 p-4 md:my-10'
        type='button'
        onClick={() => {
          toggleShowCart()
          router.push('/')
        }}
      >
        {cartStr.return_to_shop}
      </button>
      <RecommendCompetitions toggleParentModal={toggleShowCart} />
    </div>
  )
}
