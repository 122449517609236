import { svgs } from '@/utils/Images'
import PropTypes from 'prop-types'
import { ReactElement } from 'react'

/**
 * Text input field.
 *
 * @param {object} props
 *   The props object.
 */
export function TextInput(props: any): ReactElement {
  const inputClasses: string = `flex h-14 p-4 bg-white border border-solid ${
    props.errorMsg ? 'border-error' : 'border-grey'
  } disabled:bg-gray-50 disabled:opacity-75 focus:outline-none focus:ring-1 focus:ring-yellow-hex2 focus:border-transparent`

  // Return statement.
  return (
    <div className={`text-input flex w-full flex-col ${props.additionalClasses ?? ''}`}>
      {props.label ? <span className='mb-2 text-sm'>{props.label}</span> : null}
      <input
        type={props.type || 'text'}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
        placeholder={props.placeholder || ''}
        className={inputClasses}
        disabled={props.disabled}
        autoComplete={props.autoComplete}
      />
      {/* Shor error message if given. */}
      {props.errorMsg && <div className='mt-1 text-error'>{props.errorMsg}</div>}
    </div>
  )
}
TextInput.propTypes = {
  type: PropTypes.string,
  value: PropTypes.any.isRequired,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  errorMsg: PropTypes.string,
  additionalClasses: PropTypes.string,
  disabled: PropTypes.bool || undefined,
  autoComplete: PropTypes.string || undefined,
}

export function Divide(props: any): ReactElement {
  return <div className={`h-[1px] w-full bg-grey ${props.additionalClasses ?? ''}`} />
}

export function DividerContainer(props: any): ReactElement {
  return (
    <>
      <Divide additionalClasses={`my-4 ${props.additionalClasses}`} />
      {props.children}
      <Divide additionalClasses={`my-4 ${props.additionalClasses}`} />
    </>
  )
}

export function Checkbox(props: any): ReactElement {
  return (
    <label className={`relative flex cursor-pointer items-center pl-5 ${props.additionalClasses ?? ''}`}>
      <input type='checkbox' className='peer hidden' checked={props.checked} onClick={props.onClick} onChange={props.onChange} {...props.input} />
      <span
        className={`solid absolute top-1/2 left-0 h-3.5 w-3.5 -translate-y-1/2 rounded-sm border border-grey bg-white ring-1 ring-transparent peer-checked:border-transparent ${
          props.innerClasses ?? 'peer-checked:bg-blue-rgba1 peer-checked:ring-blue-rgba1'
        }`}
      >
        <svg xmlns='http://www.w3.org/2000/svg' className='h-4 w-4' viewBox='0 0 24 24' fill='#FFF'>
          {svgs.checkBox}
        </svg>
      </span>
      {props.text}
    </label>
  )
}

export function NumberInput(props: any): ReactElement {
  const min = props.min ?? 1
  const btnClass = 'px-3 sm:px-4 text-xs sm:text-lg '
  return (
    <div className={`flex w-fit flex-row items-center justify-center rounded-sm border border-blue-rgba8 ${props.additionalClasses ?? ''}`}>
      <button
        aria-label='Decrease number'
        className={btnClass + (props.value === min || props.disabled ? 'text-grey5' : 'hover:text-blue-rgba1')}
        onClick={() => {
          if (props.value > min) {
            props.onChange(Number.parseInt(props.value) - 1)
          }
        }}
        disabled={props.value === min || props.disabled}
      >
        -
      </button>
      <label className='h-full'>
        <input
          type='text'
          className='flex h-full w-10 bg-blue-rgba2 text-center text-base font-medium focus:outline-none focus:ring-2 focus:ring-blue-rgba1 sm:text-lg'
          inputMode='numeric'
          value={props.value}
          onChange={e => {
            const value = e.currentTarget.value.replace(/[^\d]+/, '')
            if (!e.currentTarget.value) {
              props.onChange('')
            } else {
              props.onChange(value >= props.max ? props.max : value)
            }
          }}
          onBlur={e => {
            const value = e.currentTarget.value
            if (value === '') {
              props.onChange(min)
            }
          }}
          disabled={props.disabled}
        />
      </label>
      <button
        aria-label='Increase number'
        className={btnClass + (props.value === props.max || props.disabled ? 'text-grey' : 'hover:text-blue-rgba1')}
        onClick={() => {
          if (props.value < props.max) {
            props.onChange(Number.parseInt(props.value) + 1)
          }
        }}
        disabled={props.value === props.max || props.disabled}
      >
        +
      </button>
    </div>
  )
}

NumberInput.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.any.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  default: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  additionalClasses: PropTypes.string,
}

export function LoadingView(props: { show: boolean; fixed?: boolean }) {
  return (
    <>
      {props.show && (
        <div
          className={`${props.fixed ? 'fixed opacity-50' : 'absolute opacity-20'} top-0 left-0 z-50 flex h-full w-full items-center justify-center bg-black`}
        >
          <svg className='h-16 w-16 animate-spin text-blue-rgba1' fill='none' viewBox='0 0 24 24'>
            <circle className='opacity-25' cx='12' cy='12' r='10' stroke='currentColor' strokeWidth='4' />
            <path
              className='opacity-75'
              fill='currentColor'
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
            />
          </svg>
        </div>
      )}
    </>
  )
}
