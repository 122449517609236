import Link from 'next/link'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import { ReactElement } from 'react'
import { svgs } from '@/utils/Images'
import { useLanguage, useUser } from '@/hooks/Contexts'

export const AltNavbar = (): ReactElement => {
  const { navigation: navStr } = useLanguage()
  const user = useUser()

  return (
    <div className='flex w-screen bg-blue-rgba2'>
      <nav className='altnav-container'>
        <AltNavbarLinks title={navStr.competitions} href='/' icon={svgs.mexplay_icons.competitions} paths={['/', '/competitions']} />
        <AltNavbarLinks title={navStr.draws} href='/draws' icon={svgs.mexplay_icons.draws} paths={['/draws']} />
        <AltNavbarLinks title={navStr.winners} href='/winners' icon={svgs.mexplay_icons.winners} paths={['/winners']} />
        <AltNavbarLinks title={navStr.entry_list} href='/entries' icon={svgs.mexplay_icons.entries} paths={['/entries']} />
        {user && user.id && (
          <AltNavbarLinks title={navStr.orders} href='/user/orders' icon={svgs.mexplay_icons.orders} paths={['/user/orders', '/user/orders/[id]']} />
        )}
      </nav>
    </div>
  )
}

const AltNavbarLinks = ({ title, href, icon, paths }): ReactElement => {
  const router = useRouter()
  const selected = paths.includes(router.pathname)
  const selectedClass = 'border-b-blue-rgba1 !text-blue-rgba1'
  return (
    <Link href={href} as={href}>
      <button className='flex'>
        <div
          className={`${
            selected ? selectedClass : ''
          } flex w-max flex-col items-center border-2 border-transparent py-1 text-white hover:border-b-blue-rgba1 hover:text-blue-rgba1`}
        >
          <div className='mb-1'>{icon}</div>
          <span className='text-xs font-semibold capitalize md:text-sm'>{title}</span>
        </div>
      </button>
    </Link>
  )
}

AltNavbarLinks.propTypes = {
  title: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
}
