import axios from 'lib/axios'
import { CountDown } from '../CountDown'
import { toast } from 'react-toastify'
import { Format } from '@/utils/Format'
import { API_URL, PERMIT_NO } from '@/utils/Consts'
import { appUtils } from 'lib/AppUtils'
import { ImgFallback } from '../Image'
import { Divide, NumberInput } from '@/components/FormElement'
import { useSWRConfig } from 'swr'
import { PrimaryButton } from '@/components/Button'
import { MexplayModalContent, MexplayModalWithImage } from '@/components/MexplayModal'
import { icons, svgs } from '@/utils/Images'
import { ExpandableBlock } from '@/components/FormElementV2'
import { Action0, Action1 } from '@/utils/Types'
import { Competition, CompetitionDetail } from '@models/Index'
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch'
import {
  useCompetitionShortNameContext,
  useLanguage,
  useLayout,
  useLocaleContext,
  useMyCart,
  useShowCartContext,
  useShowMexplayModalContext,
} from '@/hooks/Contexts'
import { useState, FC, useRef, useEffect, memo, Fragment, MutableRefObject } from 'react'
import { TagBestOdds, TagDrawTody, TagGuaranteedDraw, TagOnSale, TagSellFast } from '../Tags'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { useRouter } from 'next/router'

export const ShadowBG = (props: any) => {
  return (
    <div className='relative'>
      <div className={`absolute top-0 ${props.containerClasses ?? ''} h-full w-full`}>{props.shadow}</div>
      {props.children}
    </div>
  )
}
export function CompetitionTag(props: { tag: string }) {
  const { tag } = props
  if (tag === 'on_sale') {
    return <TagOnSale />
  } else if (tag === 'bet_odds') {
    return <TagBestOdds />
  } else if (tag === 'guaranteed_draw') {
    return <TagGuaranteedDraw />
  } else if (tag === 'selling_fast') {
    return <TagSellFast />
  } else if (tag === 'draw_today') {
    return <TagDrawTody />
  }
  return null
}

export function Price(props: { competition: Competition; inDetail?: boolean; hiddenTag?: boolean }) {
  const price = props.competition?.sale_price ? props.competition?.sale_price : props.competition?.price
  const setTag = () => {
    if (props.competition?.tag) {
      const tag = props.competition?.tag

      if (tag === 'on_sale') {
        return <TagOnSale />
      } else if (tag === 'bet_odds') {
        return <TagBestOdds />
      } else if (tag === 'guaranteed_draw') {
        return <TagGuaranteedDraw />
      } else if (tag === 'selling_fast') {
        return <TagSellFast />
      } else if (tag === 'draw_today') {
        return <TagDrawTody />
      }
    }
  }

  return (
    <div className='flex flex-wrap items-center justify-between'>
      <div className='flex flex-row items-center'>
        <span className={`relative mr-6 pl-2 font-semibold text-yellow-hex3 ${props.inDetail ? 'text-lg' : 'text-base'}`}>
          <span className='absolute left-0' style={{ top: '2px' }}>
            {Format.getCurrencySymbol()}
          </span>
          <span className={`ml-1 text-center ${props.inDetail ? 'text-4xl' : 'text-2xl'}`}>{Format.getNoDecimal(price)}</span>
          <span className='absolute left-full' style={{ top: '2px' }}>
            {Format.getDecimal(price)}
          </span>
        </span>
        {props.competition?.sale_price && (
          <span className={`font-semibold text-grey4 line-through ${props.inDetail ? 'ml-4 text-base' : 'ml-2 text-sm'}`}>
            {Format.withCurrency(props.competition?.price)}
          </span>
        )}
      </div>
      {!props.hiddenTag && setTag()}
    </div>
  )
}

interface CompetitionList {
  competitions: Competition[]
}
export const CompetitionList: FC<CompetitionList> = ({ competitions }) => {
  const {
    errors: { no_data },
  } = useLanguage()

  if (!competitions || competitions.length == 0) {
    return <div className='product-container items-center justify-center bg-light-grey py-24'>{no_data}</div>
  }
  return (
    <div className='product-out-container'>
      {competitions.map((competition, index) => {
        return <CompetitionItem key={index} competition={competition} />
      })}
    </div>
  )
}

export function CompetitionRaffleDetails(props: { time: string; tickets_total: number; containerClasses?: string }) {
  return (
    <div className={props.containerClasses}>
      <div className='flex flex-col justify-between px-2 pt-3 pb-3 text-xs md:text-sm'>
        <div className='flew-row flex items-center pb-1 drop-shadow-s1'>
          <svg xmlns='http://www.w3.org/2000/svg' className='mr-1 h-4 w-4' viewBox='0 0 20 20' fill='currentColor'>
            {svgs.clock}
          </svg>
          <CountDown date={props.time} additionalClasses='text-white' shortModel={true} />
        </div>
        <div className='flex flex-row items-center drop-shadow-s1'>
          <svg className='mr-1 h-4 w-4' viewBox='0 0 9 9' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
            {svgs.ticket}
          </svg>
          <span>
            <span>{props.tickets_total}</span>
          </span>
        </div>
      </div>
    </div>
  )
}

interface CompetitionItemProps {
  competition: Competition
}
export const CompetitionItem: FC<CompetitionItemProps> = ({ competition }) => {
  const { competitions: strings } = useLanguage()
  const { setShortName } = useCompetitionShortNameContext()

  const sectionClass = 'mt-2 flex w-full flex-row flex-wrap justify-between text-xs group-hover:text-blue-rgba1'
  return (
    <div
      className='product-container group relative w-full bg-blue-rgba2 hover:scale-[1.02] hover:duration-200'
      onClick={() => {
        setShortName(competition?.short_name)
      }}
    >
      {competition?.tickets_left == 0 && (
        <Fragment>
          <div className='sold-out-container' />
          <div className='absolute top-0 left-0 z-20 flex h-full w-full items-center justify-center'>
            {/* <span className='sold-out'>{strings.sold_out}</span> */}
          </div>
        </Fragment>
      )}
      <ImgFallback
        className='h-auto max-h-60 w-full rounded-t object-cover'
        alt={competition?.banner_image}
        src={competition?.banner_image ?? icons.noImage.src}
      />

      <div className='flex h-full flex-col justify-between p-2 sm:p-3'>
        <div>
          <Price competition={competition} />
          <div className='justify-end text-sm text-white group-hover:text-blue-rgba1'>{competition?.title}</div>
        </div>
        {competition?.draw_date && !Format.isZero(competition.draw_date) ? (
          <div className={sectionClass + ' text-error'}>
            <CountDown date={competition?.draw_date} additionalClasses='!text-xs group-hover:text-blue-rgba1' shortModel={true} />
            <div className='flex flex-row items-center'>
              <svg className='mr-1 h-3 w-3' viewBox='0 0 9 9' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
                {svgs.ticket}
              </svg>
              {competition?.tickets_left !== undefined ? (
                <span className='group-hover:text-blue-rgba1'>{strings.remaining.templated(competition?.tickets_left)}</span>
              ) : null}
            </div>
          </div>
        ) : (
          <div className={sectionClass + ' text-grey4'}>
            {competition?.tickets_left !== undefined ? (
              <div className='flex flex-row items-center'>
                <svg className='mr-1 h-3 w-3' viewBox='0 0 9 9' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
                  {svgs.ticket}
                </svg>
                <span>{strings.remaining.templated(competition?.tickets_left)}</span>
              </div>
            ) : null}
            {competition?.user_limit_amount !== undefined ? (
              <div className='flex flex-row items-center'>
                <svg className='mr-1 h-3 w-3' viewBox='0 0 9 9' fill='currentColor' xmlns='http://www.w3.org/2000/svg'>
                  {svgs.user2}
                </svg>
                <span>{strings.user_limit_desc.templated(competition?.user_limit_amount)}</span>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  )
}

interface RecommendCompetitionsProps {
  ids?: number | number[]
  toggleParentModal: Action0
}

export const RecommendCompetitions: FC<RecommendCompetitionsProps> = ({ ids, toggleParentModal }) => {
  const { locale } = useLocaleContext()
  const { competitions: strings } = useLanguage()
  const [competitions, setCompetitions] = useState<Competition[]>([])

  useEffect(() => {
    appUtils.fetcher(`${API_URL.recommend}?store_id=2${ids ? '&competition_ids=' + ids : ''}`, false, locale).then(res => setCompetitions(res))

    return () => {
      setCompetitions([])
    }
  }, [ids])
  return (
    competitions &&
    competitions.length > 0 && (
      <div className='mt-4 w-full border-t border-blue-rgba6 pt-8'>
        <div className='mb-2 w-full font-semibold md:mb-4'>{ids ? strings.related_competitions : strings.competitions_ending_soon}</div>
        <div className='product-out-container'>
          {competitions.map((competition, index) => {
            return <RelatedCompetitionItem key={index} competition={competition} toggleParentModal={toggleParentModal} />
          })}
        </div>
      </div>
    )
  )
}

interface RelatedCompetitionItemProps extends CompetitionItemProps {
  toggleParentModal: Action0
}

export const RelatedCompetitionItem: FC<RelatedCompetitionItemProps> = ({ competition, toggleParentModal }) => {
  const price = competition?.sale_price ? competition?.sale_price : competition?.price
  const { setShortName } = useCompetitionShortNameContext()
  return (
    <div
      className='product-container group hover:scale-105 hover:duration-500'
      onClick={() => {
        if (toggleParentModal) {
          toggleParentModal()
        }
        setShortName(competition?.short_name)
      }}
    >
      <ImgFallback
        className='h-auto max-h-72 w-full rounded object-cover'
        alt={competition?.banner_image}
        src={competition?.banner_image ?? icons.noImage.src}
      />

      <div className='py-2'>
        <div className='text-sm font-semibold group-hover:text-blue-rgba1'>{competition?.title}</div>
        <div className='font-semibold'>
          <span className='text-yellow-hex3'>{Format.withCurrency(price)}</span>
          {competition?.sale_price && <span className='ml-2 text-sm text-black-500 line-through'>{Format.withCurrency(competition?.price)}</span>}
        </div>
      </div>
    </div>
  )
}

interface ModalCompetitionDataProps {
  competition: CompetitionDetail
  checkout: Action0
  toggleShowCompetitionData: Action0
  showCompetitionData: boolean
  additionalClasses?: string
}

export const ModalCompetitionData: FC<ModalCompetitionDataProps> = ({
  competition,
  checkout,
  toggleShowCompetitionData,
  showCompetitionData,
  additionalClasses,
}) => {
  const mainSlider = useRef<Slider>(null)
  const { mutate, cache } = useSWRConfig()
  const { errors: errStr, competitions: competitionStr, cart: cartStr } = useLanguage()
  const [cartLoading, setCartLoading] = useState(false)
  const [ticketAmount, setTickAmount] = useState(competition?.default_ticket_amount ?? 1)
  const [availableLeft, setAvailableLeft] = useState(0)
  const [answerId, setAnswerId] = useState(0)
  const [mainSliderIndex, setMainSliderIndex] = useState(0)
  const [showFullScreenSlider, setShowFullScreenSlider] = useState<boolean>(false)
  const { setShowCart } = useShowCartContext()
  const { shortName, setShortName } = useCompetitionShortNameContext()
  const { setShowMexplayModal } = useShowMexplayModalContext()
  const { isDesktop } = useLayout()
  const router = useRouter()
  const cartCount = useMyCart()
  const { locale } = useLocaleContext()

  const [showPopup, setShowPopup] = useState(false)
  const [popupData, setPopupData] = useState<{ type: 'success' | 'image'; title: string; content: string; imgUrl: string; onConfirm: Action0 }>({
    type: 'success',
    title: '',
    imgUrl: '',
    content: '',
    onConfirm: () => {
      checkout()
      setShowPopup(false)
      setShowCart(true)
    },
  })

  const toggleFullScreenSlider = () => {
    if (showFullScreenSlider) {
      if (mainSlider && mainSlider.current) {
        mainSlider.current.slickPlay()
      }
    } else {
      if (mainSlider && mainSlider.current) {
        mainSlider.current.slickPause()
      }
    }
    setShowFullScreenSlider(prev => !prev)
  }

  const addCompetitionToCart = () => {
    axios
      .post(
        API_URL.addToCart,
        {
          cart_id: cache && cache.get(API_URL.cartCount) ? cache.get(API_URL.cartCount).id : '',
          item: {
            competition_id: competition?.id,
            qty: ticketAmount,
            answer_id: answerId,
          },
        },
        {
          headers: {
            'Accept-Language': locale,
          },
        }
      )
      .then(res => {
        setCartLoading(false)
        if (res.data && res.data.data) {
          mutate(API_URL.cartCount, { ...res.data.data }, false)
          setAvailableLeft(parseInt(`${availableLeft - ticketAmount}`))
          if (
            res.data?.data?.competition &&
            !appUtils.isEmpty(res.data?.data?.competition.pop_title) &&
            !appUtils.isEmpty(res.data?.data?.competition.pop_content) &&
            (!appUtils.isEmpty(res.data?.data?.competition.sale_price) || !appUtils.isEmpty(res.data?.data?.competition.price))
          ) {
            toast.success(appUtils.getItemsStr(ticketAmount, cartStr) + ' ' + cartStr.added_to_cart)
            const popCompetition = res.data?.data?.competition
            setPopupData({
              ...popupData,
              type: 'image',
              title: appUtils.isEmpty(popCompetition.pop_title) ? cartStr.want_to_win + popCompetition.title + '?' : popCompetition.pop_title,
              content: appUtils.isEmpty(popCompetition.pop_content)
                ? competitionStr.only_ticket.templated(Format.withCurrency(popCompetition.sale_price ?? popCompetition.price))
                : popCompetition.pop_content,
              imgUrl: popCompetition.banner_image,
              onConfirm: () => {
                setShowPopup(false)
                setShortName(popCompetition.short_name)
              },
            })
          } else {
            setPopupData({
              ...popupData,
              type: 'success',
              content: appUtils.getItemsStr(ticketAmount, cartStr) + ' ' + cartStr.added_to_cart,
            })
          }
          setShowPopup(true)
        }
      })
      .catch(e => {
        setCartLoading(false)
        toast.error(appUtils.handleApiError(e, errStr))
        const responseCode = e?.response?.data?.code
        if (responseCode == '6007' && e.response?.data.data) {
          setAvailableLeft(parseInt(e.response?.data.data.qty))
          setTickAmount(parseInt(e.response?.data.data.qty))
        }
        if (responseCode == '4002') {
          mutate(API_URL.cartCount)
        }
      })
  }

  useEffect(() => {
    if (!showCompetitionData && !competition) return

    const aLeft = Math.min(competition.user_tickets_left ?? competition.user_limit_amount, competition.tickets_left)
    setTickAmount(Math.min(aLeft, competition.default_ticket_amount ?? 1))
    setAvailableLeft(aLeft)
    setMainSliderIndex(0)
    setShowFullScreenSlider(false)
    //for testing
    setAnswerId(competition?.quiz?.answers?.find(answer => answer.is_correct === 1).id ?? 0)
    return () => {
      setAvailableLeft(0)
      setShowPopup(false)
    }
  }, [competition])
  return (
    <>
      {showCompetitionData && (
        <FullScreenSlider
          show={showFullScreenSlider && showCompetitionData}
          items={competition?.images ?? []}
          onToggle={toggleFullScreenSlider}
          mainIndex={mainSliderIndex}
        />
      )}
      <MexplayModalContent
        innerClasses={`max-w-7xl h-full xl:h-max ${
          showCompetitionData ? 'animate-fade-in-down opacity-100' : ' animate-fade-out-up opacity-0 pointer-events-none'
        } ${additionalClasses ?? ''} ${showPopup ? '!w-max !h-max' : ''}`}
        childrenContainerClasses={`${showPopup ? 'h-max' : 'h-full xl:h-max '}`}
      >
        {!showPopup && (
          <div className='main-container h-full flex-col justify-center xl:h-max'>
            <div
              className={`flex h-full w-full flex-col overflow-scroll ${
                !showPopup ? 'animate-fade-in-down opacity-100' : ' pointer-events-none animate-fade-out-up opacity-0'
              }`}
            >
              <div className='sticky top-0 z-20 mb-4 flex w-full flex-row justify-between border-b border-grey9 bg-blue-rgba2 py-3 md:py-4'>
                <span className='mr-4 text-base font-semibold md:text-lg'>{competition?.title}</span>
                <svg
                  className='-mt-1 h-8 w-8 cursor-pointer rounded border border-blue-rgba6 hover:text-blue-rgba1'
                  viewBox='0 0 24 24'
                  fill='currentColor'
                  onClick={() => {
                    if (toggleShowCompetitionData) {
                      toggleShowCompetitionData()
                    }
                  }}
                >
                  {svgs.cross}
                </svg>
              </div>
              {competition?.draw_date && !Format.isZero(competition.draw_date) && (
                <CountDown
                  date={competition?.draw_date}
                  additionalClasses='rounded text-error mb-4 text-base md:text-lg'
                  onFinish={() => {
                    toggleShowCompetitionData()
                  }}
                />
              )}

              <div className='mb-2 flex h-auto flex-col gap-4 md:mb-4 md:h-max lg:grid lg:grid-cols-2'>
                <CompetitionImageSlider
                  mainSliderRef={mainSlider}
                  items={competition?.images}
                  onToggle={toggleFullScreenSlider}
                  setMainIndex={setMainSliderIndex}
                />
                <div className='h-full md:h-max'>
                  <div className='border-t border-b border-blue-rgba6 py-2'>
                    <Price competition={competition} inDetail={true} />
                    <RaffleDetails ticketsTotal={competition?.tickets_total} userLimitAmount={competition?.user_limit_amount} />
                  </div>

                  <div className='mt-4 flex flex-row'>
                    {availableLeft > 0 && (
                      <NumberInput
                        max={availableLeft}
                        value={ticketAmount}
                        onChange={v => {
                          setTickAmount(v)
                        }}
                        additionalClasses='mr-2 sm:mr-4'
                      />
                    )}
                    <PrimaryButton
                      disabled={cartLoading || availableLeft <= 0}
                      additionalClasses='p-3 sm:p-4 flex text-sm sm:text-base mr-2 items-center'
                      onClick={e => {
                        e.preventDefault()
                        if (cartLoading) {
                          return
                        }
                        setCartLoading(true)
                        addCompetitionToCart()
                      }}
                    >
                      {availableLeft <= 0 ? (
                        <span className='text-error'>{errStr.limit_reach}</span>
                      ) : (
                        <>
                          {cartLoading && (
                            <svg className='mr-3 h-5 w-5 animate-spin' fill='none' viewBox='0 0 24 24'>
                              {svgs.refresh}
                            </svg>
                          )}
                          {cartStr.add_to_cart}
                          {!cartLoading && (
                            <span>
                              <span className='px-2'>|</span>
                              {Format.withCurrency((competition?.sale_price ?? competition?.price) * ticketAmount)}
                            </span>
                          )}
                        </>
                      )}
                    </PrimaryButton>
                    {!isDesktop && shortName ? (
                      <PrimaryButton
                        additionalClasses='p-3 !bg-blue-rgba8 group'
                        onClick={() => {
                          if (shortName) {
                            setShortName('')
                          } else {
                            setShowMexplayModal(prev => !prev)
                          }
                          setShowCart(prev => !prev)
                          // @ts-ignore
                          if (document.fullscreenElement || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement) {
                            // @ts-ignore
                            if (window.safari !== undefined) {
                              // @ts-ignore
                              document?.webkitExitFullscreen()
                            } else {
                              document?.exitFullscreen()
                            }
                          }
                        }}
                      >
                        <div className='relative'>
                          <svg className='h-8 w-8 cursor-pointer text-white group-hover:text-blue-rgba1' fill='none' viewBox='0 0 24 24' stroke='currentColor'>
                            {svgs.cart}
                          </svg>
                          {cartCount && (
                            <>
                              <span className='absolute -top-2 -right-2 rounded-full border border-solid border-yellow-hex2 bg-yellow-hex2 px-0.5 text-xs font-medium'>
                                {cartCount.items}
                              </span>
                            </>
                          )}
                        </div>
                      </PrimaryButton>
                    ) : null}
                  </div>
                  <PrizesDetails description={competition?.description} containerClasses={`mt-4 pb-4 md:pb-0 md:h-max`} />
                  {!isDesktop && <PermitNo />}
                </div>
              </div>
              {isDesktop && <PermitNo />}
            </div>
          </div>
        )}
        {showPopup && (
          <MexplayModalWithImage
            type={popupData.type}
            onClose={() => {
              const paths = ['/', '/competitions']
              setShowPopup(false)
              toggleShowCompetitionData()
              if (!paths.includes(router.pathname)) router.push('/')
            }}
            title={popupData.title}
            imgUrl={popupData.imgUrl}
            content={popupData.content}
            onConfirm={popupData.onConfirm}
            additionalClasses={`${showPopup ? 'animate-fade-in-down opacity-100' : ' pointer-events-none animate-fade-out-up opacity-0'}`}
          />
        )}
      </MexplayModalContent>
    </>
  )
}
const PermitNo = () => {
  return (
    <div className='mt-auto mb-44 flex flex-col lg:mb-0'>
      <Divide additionalClasses='!bg-grey9' />
      <div className='my-4 flex w-full flex-col space-y-2 text-xs text-grey4 opacity-75'>
        <span>{PERMIT_NO[0]}</span>
        <span>{PERMIT_NO[1]}</span>
      </div>
    </div>
  )
}
interface SliderProps {
  items: string[]
  onToggle: Action0
  mainIndex?: number
  setMainIndex?: Action1<number>
}

export const CompetitionImageSlider = memo(function CompetitionImageSlider({
  items,
  onToggle,
  mainSliderRef,
  setMainIndex,
}: SliderProps & {
  mainSliderRef: MutableRefObject<Slider>
}) {
  const layout = useLayout()
  const subSliderRef = useRef<Slider>(null)
  const [localIndex, setLocalIndex] = useState(0)
  useEffect(() => {
    setLocalIndex(0)
    setMainIndex(0)
    mainSliderRef.current?.slickGoTo(0)
    subSliderRef.current?.slickGoTo(0)
  }, [items])

  const subSliderClass = 'h-14 select-none rounded object-cover'

  return !Array.isArray(items) || items.length == 0 ? null : (
    <div className='relative w-full'>
      <button
        aria-label='Toggle fullscreen'
        className='group absolute top-2 right-2 z-10 rounded-full bg-white p-2 hover:bg-blue-rgba1'
        onClick={() => onToggle()}
      >
        <svg viewBox='0 0 45 48' fill='none' xmlns='http://www.w3.org/2000/svg' className='h-6 w-6 text-dark-5 group-hover:text-white'>
          {svgs.mexplay_icons.expand}
        </svg>
      </button>
      {items.length > 1 ? (
        <div>
          <Slider
            lazyLoad='ondemand'
            ref={mainSliderRef}
            autoplay
            asNavFor={subSliderRef.current}
            infinite={true}
            afterChange={number => {
              if (items.length <= 4) {
                setLocalIndex(number)
                setMainIndex(number)
              }
            }}
            arrows={false}
          >
            {items.map((item, index) => (
              <div className={`h-max w-full justify-center overflow-hidden rounded hover:border-0`} key={index}>
                <ImgFallback src={item} className='max-h-72 w-full select-none rounded object-cover sm:max-h-[440px]' alt={item} />
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <ImgFallback src={items[0]} className='max-h-72 w-full select-none rounded object-cover md:max-h-[440px]' alt={items[0]} />
      )}

      <div className={`relative w-full ${items.length === 1 ? 'hidden' : ''}`}>
        <button
          aria-label='Previous slide'
          className='absolute top-1/2 left-0 z-10 -translate-y-1/2 text-white hover:text-blue-rgba1'
          onClick={() => {
            if (!subSliderRef.current) {
              mainSliderRef.current.slickPrev()
            } else {
              subSliderRef.current?.slickPrev()
            }
          }}
        >
          <svg className='h-4 w-4' viewBox='0 0 24 24'>
            {svgs.arrow_left}
          </svg>
        </button>
        <div className={`flex-1 justify-center px-4 ${items.length <= 4 ? 'flex' : ''}`}>
          {items.length <= 4 ? (
            items.map((item, index) => (
              <button
                className={`flex h-full justify-center overflow-hidden rounded ${index === localIndex ? '' : 'transparent scale-75 opacity-50'}`}
                key={index}
                onClick={() => {
                  setLocalIndex(index)
                  setMainIndex(index)
                  mainSliderRef.current?.slickGoTo(index)
                }}
              >
                <ImgFallback src={item} className={subSliderClass + ' !w-full'} alt={item} />
              </button>
            ))
          ) : (
            <Slider
              lazyLoad='ondemand'
              ref={subSliderRef}
              arrows={false}
              asNavFor={mainSliderRef.current}
              slidesToShow={layout.isDesktop && items.length >= 5 ? 5 : 3}
              centerMode={true}
              slidesToScroll={1}
              afterChange={number => {
                setLocalIndex(number)
                setMainIndex(number)
              }}
              focusOnSelect={true}
              centerPadding={layout.isDesktop ? '50px' : '40px'}
            >
              {items.map((item, index) => (
                <button
                  className={`flex w-full justify-center overflow-hidden rounded p-1 ${index === localIndex ? '' : 'transparent scale-90 opacity-50'}`}
                  key={index}
                  onClick={() => {
                    setLocalIndex(index)
                    setMainIndex(index)
                    mainSliderRef.current?.slickGoTo(index)
                  }}
                >
                  <ImgFallback src={item} className={subSliderClass} alt={item} />
                </button>
              ))}
            </Slider>
          )}
        </div>
        <button
          aria-label='Next slide'
          className='absolute top-1/2 right-0 z-10 -translate-y-1/2 text-white hover:text-blue-rgba1'
          onClick={() => {
            if (!subSliderRef.current) {
              mainSliderRef.current.slickNext()
            } else {
              subSliderRef.current?.slickNext()
            }
          }}
        >
          <svg className='h-4 w-4' viewBox='0 0 24 24'>
            {svgs.arrow_right}
          </svg>
        </button>
      </div>
    </div>
  )
})

export const FullScreenSlider = function FullScreenSlider({
  items,
  onToggle,
  mainIndex,
  show,
}: {
  show: boolean
} & SliderProps) {
  const { isDesktop } = useLayout()
  const [isPanningDisabled, setIsPanningDisabled] = useState(true)
  const [isFullScreen, setIsFullScreen] = useState(false)
  const [localIndex, setLocalIndex] = useState(mainIndex)
  const [isFullScreenEnabled, setIsFullScreenEnabled] = useState(true)
  const sliderRef = useRef<Slider>(null)
  const zoomRef = useRef([])
  const imgRef = useRef([])

  useEffect(() => {
    // @ts-ignore
    setIsFullScreenEnabled(document.fullscreenEnabled || document.webkitFullscreenEnabled || document.msFullscreenEnabled)
    zoomRef.current = zoomRef.current.slice(0, items.length)
    imgRef.current = zoomRef.current.slice(0, items.length)
    if (items.length === 1) {
      setLocalIndex(0)
    }
  }, [items])

  useEffect(() => {
    setLocalIndex(mainIndex)
    if (show && items) {
      sliderRef.current.slickGoTo(mainIndex, true)
      zoomRef.current[mainIndex]?.resetTransform()
    }
    document.addEventListener('fullscreenchange', exitFullscreenHandler)
    document.addEventListener('mozfullscreenchange', exitFullscreenHandler)
    document.addEventListener('MSFullscreenChange', exitFullscreenHandler)
    document.addEventListener('webkitfullscreenchange', exitFullscreenHandler)

    return () => {
      document.removeEventListener('fullscreenchange', exitFullscreenHandler)
      document.removeEventListener('mozfullscreenchange', exitFullscreenHandler)
      document.removeEventListener('MSFullscreenChange', exitFullscreenHandler)
      document.removeEventListener('webkitfullscreenchange', exitFullscreenHandler)
    }
  }, [show])
  const exitFullscreenHandler = () => {
    // @ts-ignore
    if (!document.fullscreenElement && !document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
      setIsFullScreen(false)
    }
  }
  const exitFullscreen = () => {
    // @ts-ignore
    if (document.fullscreenElement || document.webkitIsFullScreen || document.mozFullScreen || document.msFullscreenElement) {
      // @ts-ignore
      if (window.safari !== undefined) {
        // @ts-ignore
        document?.webkitExitFullscreen()
      } else {
        document?.exitFullscreen()
      }
      setIsFullScreen(false)
    }
  }
  const btnFullscreenHandler = () => {
    if (isFullScreen) {
      exitFullscreen()
    } else {
      //checks whether window is using Safari browser
      // @ts-ignore
      if (window.safari !== undefined && isFullScreenEnabled && document.body?.webkitRequestFullscreen) {
        //fullscreen api is partially supported in Safari v 15.4 with the use of "webkit-" prefix
        //source: https://caniuse.com/fullscreen
        // @ts-ignore
        document.body?.webkitRequestFullscreen()
        setIsFullScreen(true)
      } else {
        if (isFullScreenEnabled && document.body?.requestFullscreen) {
          document.body?.requestFullscreen()
          setIsFullScreen(true)
        }
      }
    }
  }
  const btnFullscreenExitHandler = () => {
    if (isFullScreen) {
      exitFullscreen()
    }
    if (zoomRef.current[localIndex]) {
      zoomRef.current[localIndex].resetTransform()
    }
    onToggle()
  }
  return (
    <div
      className={`fullscreen-slider fixed top-0 left-0 z-50 h-screen w-screen bg-black py-12 transition-all duration-500 ${
        show ? 'translate-y-[0px] overflow-hidden opacity-100' : 'pointer-events-none -translate-y-[10px] opacity-0'
      }`}
    >
      <div className='absolute top-0 left-0 z-40 flex w-screen items-center justify-between bg-transparent px-4 pt-4'>
        <span className='text-sm text-white'>
          {localIndex + 1} / {items.length}
        </span>
        <div className='flex space-x-3'>
          <button
            aria-label='Zoom in'
            onClick={() => {
              imgRef.current[localIndex]?.focus()
              zoomRef.current[localIndex]?.zoomIn()
            }}
          >
            <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' fill='none' viewBox='0 0 24 24' stroke='white' strokeWidth={2}>
              <path strokeLinecap='round' strokeLinejoin='round' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM10 7v3m0 0v3m0-3h3m-3 0H7' />
            </svg>
          </button>
          <button
            aria-label='Zoom out'
            onClick={() => {
              imgRef.current[localIndex]?.focus()
              zoomRef.current[localIndex]?.zoomOut()
            }}
          >
            <svg xmlns='http://www.w3.org/2000/svg' className='h-5 w-5' fill='none' viewBox='0 0 24 24' stroke='white' strokeWidth={2}>
              <path strokeLinecap='round' strokeLinejoin='round' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0zM13 10H7' />
            </svg>
          </button>
          {isFullScreenEnabled ? (
            <button aria-label='Toggle fullscreen' onClick={btnFullscreenHandler}>
              {isFullScreen ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  viewBox='0 0 24 24'
                  strokeWidth='2'
                  stroke='white'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none'></path>
                  <polyline points='5 9 9 9 9 5'></polyline>
                  <line x1='3' y1='3' x2='9' y2='9'></line>
                  <polyline points='5 15 9 15 9 19'></polyline>
                  <line x1='3' y1='21' x2='9' y2='15'></line>
                  <polyline points='19 9 15 9 15 5'></polyline>
                  <line x1='15' y1='9' x2='21' y2='3'></line>
                  <polyline points='19 15 15 15 15 19'></polyline>
                  <line x1='15' y1='15' x2='21' y2='21'></line>
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-5 w-5'
                  viewBox='0 0 24 24'
                  strokeWidth='2'
                  stroke='white'
                  fill='none'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                >
                  <path stroke='none' d='M0 0h24v24H0z' fill='none'></path>
                  <polyline points='16 4 20 4 20 8'></polyline>
                  <line x1='14' y1='10' x2='20' y2='4'></line>
                  <polyline points='8 20 4 20 4 16'></polyline>
                  <line x1='4' y1='20' x2='10' y2='14'></line>
                  <polyline points='16 20 20 20 20 16'></polyline>
                  <line x1='14' y1='14' x2='20' y2='20'></line>
                  <polyline points='8 4 4 4 4 8'></polyline>
                  <line x1='4' y1='4' x2='10' y2='10'></line>
                </svg>
              )}
            </button>
          ) : null}
          <button aria-label='Exit fullscreen' onClick={btnFullscreenExitHandler}>
            <svg xmlns='http://www.w3.org/2000/svg' className='h-6 w-6' fill='none' viewBox='0 0 24 24' stroke='white' strokeWidth='2'>
              <path strokeLinecap='round' strokeLinejoin='round' d='M6 18L18 6M6 6l12 12' />
            </svg>
          </button>
        </div>
      </div>

      {/* Prev and Next buttons */}
      <div className='relative z-30 flex w-screen'>
        {items.length > 1 && (
          <Fragment>
            <button aria-label='Previous slide' className='slider-arrow left-4' onClick={() => sliderRef.current.slickPrev()}>
              <svg className='h-4 w-4' viewBox='0 0 24 24'>
                {svgs.arrow}
              </svg>
            </button>
            <button aria-label='Next slide' className='slider-arrow right-4' onClick={() => sliderRef.current.slickNext()}>
              <svg className='h-4 w-4 rotate-180' viewBox='0 -3 24 24'>
                {svgs.arrow}
              </svg>
            </button>
          </Fragment>
        )}
        <Slider
          lazyLoad='ondemand'
          className='w-screen'
          ref={sliderRef}
          afterChange={number => {
            setLocalIndex(number)
          }}
          infinite={true}
          arrows={false}
          draggable={false}
          beforeChange={oldIndex => {
            zoomRef.current[oldIndex]?.resetTransform()
          }}
        >
          {items.map((item, index) => {
            return (
              <TransformWrapper
                key={index}
                ref={el => {
                  zoomRef.current[index] = el
                }}
                panning={{ disabled: isDesktop ? false : isPanningDisabled }}
                doubleClick={{ disabled: !isDesktop }}
                onZoomStop={ref => {
                  setIsPanningDisabled(ref.state.scale === 1)
                }}
                centerZoomedOut={true}
              >
                <TransformComponent
                  wrapperStyle={{
                    margin: 'auto',
                    height: isDesktop ? 'calc(90vh)' : '100%',
                    width: isDesktop ? 'calc(100vw - 4rem)' : '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifySelf: 'center',
                    justifyContent: 'center',
                  }}
                  contentStyle={{ paddingLeft: isDesktop ? '20%' : '0', paddingRight: isDesktop ? '20%' : '0' }}
                >
                  <img ref={el => (imgRef.current[index] = el)} tabIndex={index} src={item} alt={item} className='object-contain' />
                </TransformComponent>
              </TransformWrapper>
            )
          })}
        </Slider>
      </div>
    </div>
  )
}

const PrizesDetails = (props: { description: string; containerClasses: string }) => {
  const { description, containerClasses } = props
  const {
    competitions: { prizes_details },
  } = useLanguage()
  return (
    <ExpandableBlock title={prizes_details.title} openDefault={true} additionalClasses={`${containerClasses ?? ''}`}>
      <div className='flex h-full flex-col overflow-scroll md:h-auto lg:h-72'>
        <div className='whitespace-pre-wrap text-sm text-grey4' dangerouslySetInnerHTML={{ __html: description }} />
      </div>
    </ExpandableBlock>
  )
}
interface RaffleDetailsProps {
  ticketsTotal: number
  userLimitAmount: number
}

const RaffleDetails: FC<RaffleDetailsProps> = ({ ticketsTotal, userLimitAmount }) => {
  const { competitions: competitionStr } = useLanguage()
  return (
    <div className='flex flex-row flex-wrap items-start justify-start text-xs md:justify-between'>
      {[
        { svgE: svgs.ticket, h: 9, highlight: ticketsTotal, text: ' ' + competitionStr.tickets },
        { svgE: svgs.prizes, h: 58, highlight: '1', text: competitionStr.winner },
        { svgE: svgs.user, h: 24, highlight: userLimitAmount, text: competitionStr.ticket_limits },
      ].map((item, index, { length }) => {
        return (
          <div key={index} className={`${index !== length - 1 ? 'mr-4' : ''} flex flex-row items-center`}>
            <svg className='mr-1 h-3 w-3 ' fill='currentColor' viewBox={`0 0 ${item.h} ${item.h}`} xmlns='http://www.w3.org/2000/svg'>
              {item.svgE}
            </svg>
            <span className='mr-1 font-semibold'>{item.highlight}</span> {item.text}
          </div>
        )
      })}
    </div>
  )
}
