import { postMessageType } from '@/utils/Consts'
import { Action1, RequestBody } from '@/utils/Types'
import { Address, UpdatedItem } from '@models/Index'
import { toast } from 'react-toastify'
import axios from './axios'

export const appUtils = {
  async fetcher(url: string, withCache: boolean, language?: string) {
    if (withCache) {
      try {
        const res = sessionStorage.getItem(url)
        if (res) {
          return JSON.parse(res)
        }
      } catch (error) {
        appUtils.handleSessionStorageError(error)
      }
    }
    const res = await axios.get(url, {
      headers: {
        'Accept-Language': language ? language : 'en',
      },
    })
    if (res && res.data && res.data.code == '0') {
      const data = res.data.data
      try {
        if (withCache && data) {
          if (Array.isArray(data) && data.length == 0) {
            return data
          }
          sessionStorage.setItem(url, JSON.stringify(data))
        }
      } catch (error) {
        appUtils.handleSessionStorageError(error)
      }

      return data
    }
    throw new Error('An error occurred while fetching the data.')
  },

  getErrorMsgByCode(code: string, errStr: {}, msg?: string) {
    if (errStr[code] !== undefined) {
      return errStr[code]
    } else if (errStr[msg] !== undefined) {
      return errStr[msg]
    } else {
      return msg && code > '500' ? msg : errStr['general_error']
    }
  },

  handleApiError(e, errStr: {}) {
    if (e?.response?.data?.code) {
      const resCode: string = e.response.data.code
      const errorData = e.response.data.data
      return this.getErrorMsgByCode(resCode, errStr, e.response?.data?.message).templated(errorData ? Object.values(errorData) : '0')
    } else {
      return this.getErrorMsgByCode('general_error', errStr)
    }
  },

  async postData(
    url: string,
    params: RequestBody,
    language: string,
    errStr: {},
    toastError?: boolean,
    setLoading?: Action1<boolean>,
    onError?: Action1<string>
  ) {
    let errorMessage = ''
    if (setLoading) {
      setLoading(true)
    }
    const res = await axios
      .post(url, params, {
        headers: {
          'Accept-Language': language ? language : 'en',
        },
      })
      .then(res => {
        if (setLoading) {
          setLoading(false)
        }
        if (res && res.data && res.data.code != '0') {
          errorMessage = this.getErrorMsgByCode(res.data.code, errStr)
          return null
        }
        return res.data.data
      })
      .catch(e => {
        if (setLoading) {
          setLoading(false)
        }
        errorMessage = this.handleApiError(e, errStr)
      })
    if (errorMessage) {
      if (toastError) {
        toast.error(errorMessage)
      }
      if (onError) {
        onError(errorMessage)
      }
      return null
    }
    return res
  },

  addressFormat(address: Address) {
    return (
      this.getString(address.street_2, ', ') +
      this.getString(address.street, ', ') +
      this.getString(address.county, ', ') +
      this.getString(address.city, ', ') +
      this.getString(address.postcode, ', ') +
      this.getString(address.country)
    )
  },

  getString(str: string, sufx?: string) {
    return str ? str + (sufx ?? '') : ''
  },

  getItemsStr(itemNumber: number, cartStr: {}) {
    const itemStrCode = itemNumber > 1 ? ' items' : ' item'
    if (cartStr[itemStrCode] !== undefined) {
      return itemNumber + cartStr[itemStrCode]
    }
    return itemNumber + itemStrCode
  },

  isEmpty(obj: any) {
    if (!obj || obj.length == 0) {
      return true
    }
    switch (obj) {
      case '':
      case 0:
      case '0':
      case null:
      case false:
      case typeof obj == 'undefined':
        return true
      default:
        return false
    }
  },
  getInventoryAlert(updatedItems: UpdatedItem[], errStr: {}) {
    return updatedItems
      .map(item => {
        const str = item.limit ? errStr['limited_per_user'].templated(item.limit) : errStr['stock_remaining'].templated(item.qty)
        return '"' + item.item_name + '"' + str
      })
      .join('\n')
  },

  getDefaultCurrency() {
    return 'GBP' // TODO 根据 域名 不同
  },
  isFakeEmail(email: string) {
    return email && (email.endsWith('google.com') || email.endsWith('facebook.com') || email.endsWith('twitter.com') || email.endsWith('twitteroauth2'))
  },

  postNeedMessage(type: postMessageType) {
    const message = {
      type: type,
    }
    if (window.parent) {
      window.parent.postMessage(JSON.stringify(message), '*')
    }
    //@ts-ignore
    if (window.ReactNativeWebView) {
      //@ts-ignore
      window.ReactNativeWebView.postMessage(JSON.stringify(message), '*')
    }
  },

  handleSessionStorageError(error) {
    if (error instanceof DOMException) {
      console.log('Session storage is not enabled in your device.')
    } else {
      console.log(error)
    }
  },
}
