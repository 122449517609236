import { useLanguage, useLayout } from '@/hooks/Contexts'
import { svgs } from '@/utils/Images'
import { Action1 } from '@/utils/Types'
import { useEffect, useRef } from 'react'
import { FC, InputHTMLAttributes, useState } from 'react'

interface TextInputProps {
  label: string
  outerClasses?: string
  additionalClasses?: string
  labelAdditionalClasses?: string
  input: InputHTMLAttributes<HTMLInputElement>
  showValidated?: boolean
  required?: boolean
  valid?: boolean
  tipMessage?: string
  visibility?: boolean
}

export const TextInput = ({
  label,
  input,
  visibility,
  showValidated,
  valid,
  required,
  tipMessage,
  additionalClasses,
  labelAdditionalClasses,
  outerClasses,
}: TextInputProps) => {
  const [isFocus, setIsFocus] = useState(false)
  const textClass = showValidated ? (valid ? 'text-success' : 'text-error') : 'text-grey7'
  const borderClass = showValidated ? (valid ? 'border-success' : 'border-error') : isFocus ? 'border-blue-rgba1' : 'border-grey7'

  const [isVisible, setIsVisible] = useState<boolean>(false)
  const divRef = useRef(null as HTMLInputElement | null)

  return (
    <div className={textClass + ' ' + (outerClasses ?? '')}>
      <div
        ref={divRef}
        className={`solid relative flex items-center rounded border py-3 px-2 transition md:px-4 ${borderClass} ${additionalClasses ?? ''}`}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
      >
        <label className={`absolute bottom-full left-1 translate-y-1/4 bg-grey10 px-2 text-[10px] text-grey4 md:text-xs ${labelAdditionalClasses ?? ''}`}>
          {label}
          {required && <span className='ml-1 text-error'>*</span>}
        </label>

        <input tabIndex={0} className='w-full flex-1 bg-transparent text-sm text-white' {...input} type={visibility && isVisible ? 'text' : input.type} />
        {visibility && (
          <svg className='h-6 w-6 cursor-pointer text-grey-2' viewBox='0 0 720 560' onClick={() => setIsVisible(!isVisible)}>
            {isVisible ? svgs.visibility : svgs.visibilityOff}
          </svg>
        )}
        {showValidated && (
          <svg
            className='cursor-pointer'
            width='14'
            height='14'
            viewBox='0 0 14 14'
            fill='currentColor'
            onClick={() => {
              if (!valid && divRef?.current) {
                const input = divRef.current.getElementsByTagName('input')[0]
                if (input) {
                  input.value = ''
                }
              }
            }}
          >
            <circle cx='7' cy='7' r='7' />
            {valid ? (
              <path d='M4 7.91638L6.08398 10L10 5' stroke='white' />
            ) : (
              <path
                d='M4.12588 4.12588C4.16568 4.08598 4.21297 4.05432 4.26502 4.03272C4.31708 4.01112 4.37288 4 4.42924 4C4.4856 4 4.54141 4.01112 4.59347 4.03272C4.64552 4.05432 4.6928 4.08598 4.73261 4.12588L7.00011 6.39424L9.26762 4.12588C9.30746 4.08604 9.35475 4.05444 9.4068 4.03288C9.45885 4.01132 9.51464 4.00022 9.57098 4.00022C9.62732 4.00022 9.68311 4.01132 9.73516 4.03288C9.78721 4.05444 9.83451 4.08604 9.87434 4.12588C9.91418 4.16572 9.94578 4.21301 9.96734 4.26507C9.9889 4.31712 10 4.3729 10 4.42924C10 4.48558 9.9889 4.54137 9.96734 4.59342C9.94578 4.64547 9.91418 4.69277 9.87434 4.73261L7.60598 7.00011L9.87434 9.26762C9.91418 9.30746 9.94578 9.35475 9.96734 9.4068C9.9889 9.45885 10 9.51464 10 9.57098C10 9.62732 9.9889 9.68311 9.96734 9.73516C9.94578 9.78721 9.91418 9.83451 9.87434 9.87434C9.83451 9.91418 9.78721 9.94578 9.73516 9.96734C9.68311 9.9889 9.62732 10 9.57098 10C9.51464 10 9.45885 9.9889 9.4068 9.96734C9.35475 9.94578 9.30746 9.91418 9.26762 9.87434L7.00011 7.60598L4.73261 9.87434C4.69277 9.91418 4.64547 9.94578 4.59342 9.96734C4.54137 9.9889 4.48558 10 4.42924 10C4.3729 10 4.31712 9.9889 4.26507 9.96734C4.21301 9.94578 4.16572 9.91418 4.12588 9.87434C4.08604 9.83451 4.05444 9.78721 4.03288 9.73516C4.01132 9.68311 4.00022 9.62732 4.00022 9.57098C4.00022 9.51464 4.01132 9.45885 4.03288 9.4068C4.05444 9.35475 4.08604 9.30746 4.12588 9.26762L6.39424 7.00011L4.12588 4.73261C4.08598 4.6928 4.05432 4.64552 4.03272 4.59347C4.01112 4.54141 4 4.4856 4 4.42924C4 4.37288 4.01112 4.31708 4.03272 4.26502C4.05432 4.21297 4.08598 4.16568 4.12588 4.12588Z'
                fill='white'
              />
            )}
          </svg>
        )}
      </div>
      {tipMessage && <div className='mt-1 text-xs'>{tipMessage}</div>}
    </div>
  )
}

interface DropdownProps extends Omit<TextInputProps, 'valid' | 'input'> {
  input?: InputHTMLAttributes<HTMLInputElement>
  value: string
  onSelect: Action1<string>
  options: string[]
  toggleClasses?: string
  optionClasses?: string
}

export const Dropdown = ({ label, additionalClasses, toggleClasses, optionClasses, input, value, options, onSelect }: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false)
  const layout = useLayout()
  return (
    <div
      className={`solid relative text-sm text-white ${isOpen ? 'rounded-t border-blue-rgba1' : 'rounded border-grey7'} flex items-center transition ${
        additionalClasses ?? ''
      }`}
      onClick={() => setIsOpen(!isOpen)}
      onMouseLeave={() => {
        setIsOpen(false)
      }}
    >
      <label className='absolute bottom-full left-1 translate-y-1/4 bg-white px-2 text-xs text-grey4'>{label}</label>
      <input className={`w-full flex-1 cursor-pointer font-semibold ${toggleClasses}`} readOnly value={value} {...input} />
      <div
        className={`solid absolute top-full -left-px z-10 rounded-b border-x border-b border-blue-rgba1 transition ${
          isOpen ? 'animate-[fade-in-down_150ms_ease-out]' : 'pointer-events-none animate-[fade-out-up_150ms_ease-out_forwards]'
        }`}
        style={{ width: layout.isDesktop ? 'calc(100% + 2px)' : 'min-w-max' }}
      >
        {options.map((item, index) => (
          <button
            onClick={() => {
              setIsOpen(false)
              onSelect(item)
            }}
            className={`solid w-full border-b border-grey7 text-left transition first:border-t last:rounded-b last:border-0  ${
              value == item ? 'bg-yellow-1 font-semibold' : ' bg-white'
            } ${optionClasses}`}
            key={index}
          >
            {item}
          </button>
        ))}
      </div>
      <span className={`cursor-pointer transition ${isOpen ? 'rotate-180' : 'rotate-0'}`}>
        <svg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
          <path d='M1 1L5.5 5.5L10 1' stroke='#4F4F4F' />
        </svg>
      </span>
    </div>
  )
}

export function SearchBarDropdown(
  props: {
    onSelect: Action1<string>
    defOptions: Array<string>
    searchable?: boolean
    closeAnimate?: boolean
    showValidated?: boolean
    valid: boolean
    tipMessage: string
    listContainerClass?: string
    toggleDropDownOpen?: Action1<boolean>
  } & Omit<TextInputProps, 'showValidated' | 'valid' | 'tipMessage' | 'visibility'>
) {
  const { isDesktop } = useLayout()

  const {
    user: { search_here },
  } = useLanguage()

  const { label, required, defOptions, searchable, input, onSelect, closeAnimate, showValidated, valid, tipMessage, listContainerClass, toggleDropDownOpen } =
    props

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [options, setOptions] = useState<string[]>(defOptions)

  const { className: inputClasses } = input

  const textClass = showValidated ? (valid ? 'text-success' : 'text-error') : 'text-grey7'
  const borderClass = showValidated ? (valid ? 'border-success' : 'border-error') : isOpen ? 'border-blue-rgba1' : 'border-grey7'

  const inputProps: InputHTMLAttributes<HTMLInputElement> = {
    ...input,
    // onFocus: e => {
    //   e.stopPropagation()
    //   if (!isOpen) {
    //     setIsOpen(!isOpen)
    //   }
    // },
    onClick: e => {
      e.stopPropagation()
      setIsOpen(!isOpen)
    },
  }

  useEffect(() => {
    if (toggleDropDownOpen) {
      toggleDropDownOpen(isOpen)
    }
  }, [isOpen])
  return (
    <div className={textClass ?? ''}>
      <div
        className={`solid relative flex items-center border py-3 px-2 text-sm text-white transition md:px-4 ${isOpen ? 'rounded-b' : 'rounded'} ${
          borderClass ?? ''
        } ${props.additionalClasses ?? ''}`}
        onMouseLeave={() => {
          setIsOpen(false)
        }}
      >
        <label className={`absolute bottom-full left-1 translate-y-1/4 bg-grey10 px-2 text-[10px] text-grey4 md:text-xs ${props.labelAdditionalClasses ?? ''}`}>
          {label}
          {required && <span className='ml-1 text-error'>*</span>}
        </label>
        {searchable ? (
          <input
            {...inputProps}
            className={`${inputClasses} bg-transparent`}
            placeholder={search_here}
            onChange={e => {
              setOptions(defOptions.filter(option => option.toLowerCase().includes(e.target.value.toLowerCase())))
            }}
          />
        ) : (
          <input {...inputProps} className={`${inputClasses} bg-transparent`} readOnly />
        )}
        <span className={`cursor-pointer transition ${isOpen ? 'rotate-180' : 'rotate-0'}`} onClick={() => setIsOpen(!isOpen)}>
          <svg width='11' height='7' viewBox='0 0 11 7' fill='none' xmlns='http://www.w3.org/2000/svg'>
            <path d='M1 1L5.5 5.5L10 1' stroke='white' />
          </svg>
        </span>
        <ul
          className={`solid absolute -top-48 -left-px z-10 max-h-48 !overflow-y-auto rounded-t border-x border-t md:-top-64 md:max-h-64 lg:-top-40 ${
            listContainerClass ?? ''
          } ${borderClass ?? 'border-blue-rgba1'} bg-grey10 transition ${
            isOpen ? 'animate-[fade-in-down_150ms_ease-out]' : closeAnimate ? 'animate-[fade-out-up_150ms_ease-out_forwards]' : 'hidden'
          }`}
          style={{ width: isDesktop ? 'calc(100% + 2px)' : 'min-w-max' }}
        >
          {options.map((option, index) => {
            return (
              <button
                type='button'
                key={index}
                onClick={() => {
                  setIsOpen(false)
                  onSelect(option)
                }}
                className={`solid w-full border-b border-grey7 bg-transparent px-2 py-2 text-left transition first:border-t last:rounded-b last:border-0 hover:bg-blue-rgba1 md:px-4`}
              >
                {option}
              </button>
            )
          })}
        </ul>
      </div>
      {tipMessage && <div className='mt-1 text-xs'>{tipMessage}</div>}
    </div>
  )
}
export interface ExpandableBlockProps {
  title: string
  desc?: string
  additionalClasses?: string
  notExpandable?: boolean
  openDefault?: boolean
  close?: void
}

export const ExpandableBlock: FC<ExpandableBlockProps> = ({ title, desc, additionalClasses, children, notExpandable, openDefault }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (openDefault) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [openDefault])

  if (notExpandable) {
    return (
      <div className={`mb-4 px-4 pb-1.5 ${additionalClasses ?? ''}`}>
        <span className='block py-5 text-sm font-semibold'>{title}</span>
        {children}
      </div>
    )
  }

  return (
    <div className={`text-grey4 ${additionalClasses ?? ''}`}>
      <div className='ml-4 flex w-max rounded-t bg-grey6 px-4 py-2 text-sm font-semibold md:px-4'>{title}</div>
      {desc && <span className='break-all text-xs'>{desc}</span>}
      {isOpen && <div className='animate-fade-in-down rounded bg-grey6 p-2 md:p-4'>{children}</div>}
    </div>
  )
}
