import { FC } from 'react'
import { svgs } from '@/utils/Images'
import { useModalMexplay, useScrollable } from '@/hooks/Index'
import { ModalCompetitionData } from './Layouts/Competition'
import { useModalCompetition, useModalCart } from '@/hooks/Index'
import { LoadingView } from './FormElement'
import { ModalCartDetails } from './Cart'
import { useLanguage } from '@/hooks/Contexts'

const MexplayModal = () => {
  const { showMexplayModal, toggleShowMexplayModal } = useModalMexplay()
  const {
    isLoading: competitionLoading,
    showCompetitionData,
    competitionData,
    toggleShowCompetitionData,
    checkout,
  } = useModalCompetition(toggleShowMexplayModal)
  const { showCart, toggleShowCart } = useModalCart(toggleShowMexplayModal)
  useScrollable(showMexplayModal)
  return (
    <>
      <LoadingView fixed={true} show={competitionLoading} />
      <div
        className={`fixed top-0 left-0 z-50 flex h-full w-full flex-col transition-all duration-500 ${
          showMexplayModal ? ' opacity-100' : 'pointer-events-none -translate-y-[10px] opacity-0'
        } items-center justify-center`}
        aria-labelledby='modal'
        role='dialog'
        aria-modal='true'
      >
        <div className={`fixed inset-0 bg-black-850`} aria-hidden='true' />
        {showCompetitionData && (
          <ModalCompetitionData
            competition={competitionData}
            toggleShowCompetitionData={toggleShowCompetitionData}
            showCompetitionData={showCompetitionData}
            checkout={checkout}
          />
        )}
        {showCart && <ModalCartDetails showCart={showCart} toggleShowCart={toggleShowCart} />}
      </div>
    </>
  )
}
interface MexplayModalContentProps {
  innerClasses: string
  childrenContainerClasses: string
}

export const MexplayModalContent: FC<MexplayModalContentProps> = ({ innerClasses, childrenContainerClasses, children }) => {
  return (
    <div className={`relative w-full rounded-lg bg-blue-rgba2 shadow md:mx-auto ${innerClasses ?? ''}`}>
      <div className={`${childrenContainerClasses ?? 'p-4 pt-2 md:p-6 md:pt-4 lg:p-8 lg:pt-4'}`}>{children}</div>
    </div>
  )
}

interface MexplayModalWithImageProps {
  onClose?: () => void
  type?: 'success' | 'image' | 'warning'
  imgUrl?: string
  content?: string
  title?: string
  onConfirm?: () => void
  additionalClasses?: string
}

export const MexplayModalWithImage: FC<MexplayModalWithImageProps> = ({ onClose, onConfirm, type, imgUrl, title, content, additionalClasses, children }) => {
  const { competitions: strings, cart: cartStr } = useLanguage()

  const close = () => {
    if (onClose) {
      onClose()
    }
  }

  const confirm = () => {
    if (onConfirm) {
      onConfirm()
    }
  }

  const successImageClass = 'rounded-xl bg-success'
  const imageClass = 'h-auto'

  return (
    <div className={`h-full ${additionalClasses ?? ''}`}>
      <div className={`p-4 ${type !== 'image' ? 'w-full lg:w-96' : 'max-w-[360px] md:max-w-[480px]'} h-full justify-center`}>
        {onClose && (
          <div className='sticky top-0 z-20 mt-2 flex w-full flex-row justify-end rounded bg-blue-rgba2'>
            <svg
              className='-mt-1 h-8 w-8 cursor-pointer rounded border border-blue-rgba6 hover:text-blue-rgba1'
              viewBox='0 0 24 24'
              fill='currentColor'
              onClick={() => {
                if (onClose) {
                  onClose()
                }
              }}
            >
              {svgs.cross}
            </svg>
          </div>
        )}
        <div className='mt-4'>
          {/* Image */}
          <div className={`mb-6 flex w-full min-w-fit justify-center ${type === 'success' ? successImageClass : imageClass}`}>
            {type === 'image' && imgUrl && <img src={imgUrl} alt='This is an image' className='h-auto max-h-80 w-full rounded object-fill' />}
            {type === 'success' && (
              <svg className='my-8 w-1/4 fill-white' viewBox='0 0 330 330'>
                {svgs.check}
              </svg>
            )}
            {type === 'warning' && (
              <svg className='my-8 w-1/4 fill-white' viewBox='0 0 330 330' stroke='currentColor' strokeWidth={2}>
                {svgs.warning}
              </svg>
            )}
          </div>

          {/* Main content */}
          <div className='mb-6 text-center'>
            {type === 'image' && title && (
              <p className='mb-2 text-sm font-semibold md:text-base'>
                {/* <span className='font-normal'>{cartStr.want_to_win}</span> */}
                {title}
              </p>
            )}
            {type === 'success' && <p className='mb-2 text-sm font-semibold md:text-base'>{cartStr.congratulations}</p>}
            <p className='text-sm md:text-base'>{content}</p>
          </div>
          <div className='grid w-full grid-cols-2 gap-4'>
            {type === 'image' && (
              <>
                <button
                  className='solid flex-1 rounded border border-error py-3 text-center text-sm font-semibold text-error hover:bg-error hover:text-white'
                  onClick={close}
                >
                  {strings.maybe_later}
                </button>
                <button className='solid btn-pay flex-1 rounded py-3 !text-sm' onClick={confirm}>
                  {strings.details}
                </button>
              </>
            )}
            {(type === 'success' || type === 'warning') && (
              <>
                <button className='btn-transparent p-4' onClick={close}>
                  {cartStr.keep_shopping}
                </button>
                <button className='btn-white p-4' onClick={confirm}>
                  {type === 'warning' ? cartStr.confirm : cartStr.checkout}
                </button>
              </>
            )}
          </div>
          {children}
        </div>
      </div>
    </div>
  )
}

export default MexplayModal
