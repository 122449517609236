import { useLanguage } from '@/hooks/Contexts'
import { svgs } from '@/utils/Images'
import { Fragment } from 'react'
import Link from 'next/link'
import Head from 'next/head'

export default function Maintenance() {
  const { maintenance: strings, navigation: navStr } = useLanguage()

  return (
    <div className='h-screen w-screen bg-blue-rgba2'>
      <Head>
        <title>
          {strings.titles} - {navStr.app_name}
        </title>
        <link rel='icon' href='/assets/favicons/favicon.ico' />
      </Head>
      <Fragment>
        <div className='main-container h-full animate-fade-in-down flex-col gap-6 md:gap-8'>
          <svg className='h-20 md:h-24' viewBox='0 0 220 79' fill='none' xmlns='http://www.w3.org/2000/svg'>
            {svgs.mexplay_icons.mexplay_logo}
          </svg>
          <p className='pb-8 text-xl font-semibold md:text-2xl'>{strings.titles}</p>
          {strings.paragraphs.map((paragraph, i) => (
            <p key={`paragraph-${i}`} className='mb-2 text-center text-base last:mb-0 md:text-lg'>
              {paragraph}
            </p>
          ))}
          <Link href='https://www.facebook.com/mexplay.mx/'>
            <span className='cursor-pointer text-center text-sm text-grey7 underline hover:text-blue-rgba1 md:text-base'>{strings.hyperlink}</span>
          </Link>
        </div>
      </Fragment>
    </div>
  )
}
