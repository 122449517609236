import '../styles/globals.css'
import '../utils/Extensions'
import PropTypes from 'prop-types'
import { useEffect, useState, useCallback, useMemo } from 'react'
// import TagManager from 'react-gtm-module'
import { hotjar } from 'react-hotjar'
import {
  mobileLayout,
  desktopLayout,
  LayoutContext,
  LanguageContext,
  UserContext,
  SystemConfigContext,
  ShowCartContext,
  CompetitionShortNameContext,
  ShowMexplayModalContext,
  LocaleContext,
  defaultSystemConfig,
  MyCartContext,
  StatsContext,
  defaultStats,
} from '@/hooks/Contexts'
import spanish from '../public/lang/es-MX.json'
import english from '../public/lang/en.json'
import { useRouter } from 'next/router'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { API_URL } from '@/utils/Consts'
import { appUtils } from 'lib/AppUtils'
import { CartCount } from '@models/Response'
import useSWR from 'swr'
import Maintenance from './maintenance'
import { AltNavbar } from '@/components/Navigation/AltNavbar'
import { User } from '@models/Index'
import MexplayModal from '@/components/MexplayModal'
import { BannerSlider } from '@/components/Slider/BannerSlider'
import axios from 'lib/axios'

function MyApp(props: any) {
  const [system, setSystem] = useState(defaultSystemConfig)
  const [layout, setLayout] = useState(mobileLayout)
  const [strings, setStrings] = useState(english)
  const [locale, setLocale] = useState<string>(null)
  const [stats, setStats] = useState(defaultStats)
  const [user, setUser] = useState(null as User)
  const [shortName, setShortName] = useState('')
  const [showCart, setShowCart] = useState(false)
  const [showMexplayModal, setShowMexplayModal] = useState(false)
  const router = useRouter()
  const { token, lang } = router.query

  const shortNameProviderValue = useMemo(() => ({ shortName, setShortName }), [shortName, setShortName])
  const showCartProviderValue = useMemo(() => ({ showCart, setShowCart }), [showCart, setShowCart])
  const showMexplayModalProviderValue = useMemo(() => ({ showMexplayModal, setShowMexplayModal }), [showMexplayModal, setShowMexplayModal])
  const localeProviderValue = useMemo(() => ({ locale, setLocale }), [locale, setLocale])
  const [initialed, setInitialed] = useState(false)

  useEffect(() => {
    if (!router.isReady) return
    if (process.env.NEXT_PUBLIC_HOT_JAR_ID) {
      const hotId = process.env.NEXT_PUBLIC_HOT_JAR_ID
      hotjar.initialize(Number.parseInt(hotId), 6)
    }

    /**
     * System related
     */
    if (typeof window !== undefined) setSystem(prev => ({ ...prev, isSafari: /^((?!chrome|android).)*safari/i.test(navigator.userAgent.toLowerCase()) }))

    setSystem(prev => ({
      ...prev,
      screen: {
        width: window.innerWidth,
        height: window.innerHeight,
      },
    }))
    appUtils
      .fetcher(API_URL.stats, true, locale)
      .then(res => {
        if (res) setStats(res)
      })
      .catch(() => {})
  }, [router.isReady])

  // // Register service worker
  // useEffect(() => {
  //   if ('serviceWorker' in navigator) {
  //     window.addEventListener('load', function () {
  //       navigator.serviceWorker.register('/serviceWorker.js').then()
  //       // function (registration) {
  //       //   console.log('registration successful with scope: ', registration.scope)
  //       // },
  //       // function (err) {
  //       //   console.log('registration failed: ', err)
  //       // }
  //     })
  //   }
  // }, [])

  /**
   * Multi Language support
   */
  useEffect(() => {
    if (locale || !router.isReady) return
    switch (lang) {
      case 'es':
        setStrings(spanish)
        setLocale('es')
        return
      default:
        setStrings(english)
        setLocale('en')
        return
    }
  }, [lang, router.isReady])

  /**
   * Layout Context
   */
  const onResize = useCallback(() => {
    const windowWidth = window.innerWidth
    if (windowWidth >= 768) {
      if (!layout.isDesktop) {
        setLayout(desktopLayout)
      }
    } else {
      if (layout.isDesktop) {
        setLayout(mobileLayout)
      }
    }
  }, [layout])

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
  }, [onResize])
  useEffect(() => {
    if (!token && user) {
      return
    }
    if (router.isReady) {
      if (token) {
        if (token !== sessionStorage.getItem('previousToken')) {
          axios
            .post(API_URL.logout)
            .catch(e => {
              console.log(e)
            })
            .finally(() => {
              doAuth(API_URL.playerAuth + '?token=' + token)
            })
        } else {
          doAuth(API_URL.playerAuth + '?token=' + token)
        }
        sessionStorage.setItem('previousToken', token.toString())
      } else {
        doAuth(API_URL.getUserInfo)
      }
    }
  }, [token, router.isReady])

  const doAuth = (url: string) => {
    appUtils
      .fetcher(url, false, locale)
      .then(res => {
        if (res) {
          setUser(res)
        } else {
          setUser(null)
        }
      })
      .catch(() => {
        setUser(null)
      })
      .finally(() => {
        setInitialed(true)
      })
  }

  const { data: cartCount } = useSWR<CartCount>(!initialed ? null : API_URL.cartCount, appUtils.fetcher, {
    shouldRetryOnError: false,
    focusThrottleInterval: 60000,
    onError: err => {
      if (err && err.response) {
        if (err.response.status === 503) {
          if (!system.isMaintain) {
            setSystem(prev => ({ ...prev, isMaintain: true }))
          }
        } else if (system.isMaintain) {
          setSystem(prev => ({ ...prev, isMaintain: false }))
        }
      }
      // else if ('Network Error' == err.message) {
      //   if (!system.isMaintain) {
      //     setSystem(prev => ({ ...prev, isMaintain: true }))
      //   }
      // }
    },
    errorRetryCount: 5,
  })

  return (
    <SystemConfigContext.Provider value={system}>
      <LayoutContext.Provider value={layout}>
        <LanguageContext.Provider value={strings}>
          {system.isMaintain ? (
            <Maintenance />
          ) : (
            <LocaleContext.Provider value={localeProviderValue}>
              <StatsContext.Provider value={stats}>
                <UserContext.Provider value={user}>
                  <MyCartContext.Provider value={cartCount}>
                    <ShowMexplayModalContext.Provider value={showMexplayModalProviderValue}>
                      <ShowCartContext.Provider value={showCartProviderValue}>
                        <CompetitionShortNameContext.Provider value={shortNameProviderValue}>
                          {router.pathname !== '/orderDetails' ? (
                            <>
                              <BannerSlider />
                              <AltNavbar />
                              <MexplayModal />
                              <ToastContainer className='min-w-fit text-sm md:text-base' position='top-center' limit={3} />
                            </>
                          ) : null}
                          <props.Component {...props.pageProps} />
                        </CompetitionShortNameContext.Provider>
                      </ShowCartContext.Provider>
                    </ShowMexplayModalContext.Provider>
                  </MyCartContext.Provider>
                </UserContext.Provider>
              </StatsContext.Provider>
            </LocaleContext.Provider>
          )}
        </LanguageContext.Provider>
      </LayoutContext.Provider>
    </SystemConfigContext.Provider>
  )
}

MyApp.propTypes = {
  Component: PropTypes.elementType,
  pageProps: PropTypes.object,
}

export default MyApp
