import { svgs } from '@/utils/Images'
import PropTypes from 'prop-types'
import { ReactElement } from 'react'

/**
 * The Primary Button. Primarily used for CTAs.
 *
 * @param {object} props
 *   The props object.
 */
export function PrimaryButton(props: any): ReactElement {
  const classes: string = `${props.disabled ? 'btn-disabled ' : 'btn-primary '} ${props.additionalClasses ?? ''}`
  return (
    <button type='button' className={classes} onClick={props.onClick} disabled={props.disabled}>
      {props.children}
    </button>
  )
}

PrimaryButton.propTypes = {
  onClick: PropTypes.func,
  children: PropTypes.any,
  additionalClasses: PropTypes.string,
  disabled: PropTypes.bool,
}

type ButtonWithLoadingProps = {
  title: string
  onClick?: () => void
  isLoading: boolean
  className?: string
  type?: 'button' | 'submit'
  disabled?: boolean
  additionalClasses?: string
}

export function ButtonWithLoading(props: ButtonWithLoadingProps) {
  const btnMainClass = props.disabled || props.isLoading ? 'btn-disabled' : 'btn-primary'
  return (
    <button
      className={(props.className ?? btnMainClass) + ` flex items-center justify-center ${props.additionalClasses ?? ''}`}
      disabled={props.isLoading ? true : props.disabled}
      type={props.type ?? 'button'}
      onClick={e => {
        if (!props.isLoading && props.onClick) {
          e.preventDefault()
          props.onClick()
        }
      }}
    >
      <svg
        className={`mr-2 h-5 w-5 animate-spin text-blue-rgba1 ${props.isLoading ? ' block' : 'hidden'}`}
        xmlns='http://www.w3.org/2000/svg'
        fill='none'
        viewBox='0 0 24 24'
      >
        {svgs.refresh}
      </svg>

      {props.title}
    </button>
  )
}
