import { useLanguage } from '@/hooks/Contexts'
import { Format } from '@/utils/Format'
import { Action0 } from '@/utils/Types'
import moment, { Moment } from 'moment'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

function getDiffObjFromNow(date: Moment, shortModel: boolean, ms?: boolean, timeLang?: Record<string, string>) {
  const now = moment()
  const secondsDiff = date.diff(now, 'seconds')
  if (ms) {
    return Format.minuteSecondMillis(secondsDiff, timeLang)
  }
  return Format.dayHourMinuteIfMoreThanOneDay(secondsDiff, shortModel, timeLang)
}

export function CountDown(props: { additionalClasses?: string; date: string | null; onFinish?: Action0; shortModel?: boolean; ms?: boolean }) {
  const { date, onFinish, shortModel, ms } = props
  const router = useRouter()
  const { competitions } = useLanguage()
  const [dateObj, setDateObj] = useState(
    date && router.isReady && competitions.time ? getDiffObjFromNow(moment.utc(date).local(), shortModel, ms, competitions.time) : null
  )

  useEffect(() => {
    const utc2now = moment.utc(date).local()
    let token: any | null = null
    if (date && competitions.time && router.isReady) {
      token = setInterval(() => {
        setDateObj(getDiffObjFromNow(utc2now, shortModel, ms, competitions.time))
      }, 1000)

      if (onFinish && utc2now.isBefore(moment())) {
        onFinish()
      }
    }

    return () => {
      if (token) clearInterval(token)
    }
  }, [date, onFinish, router.isReady])
  const objKeys = dateObj ? Object.keys(dateObj) : []
  return (
    router.isReady &&
    (shortModel || ms ? (
      <span suppressHydrationWarning className={`text-sm ${props.additionalClasses ?? ''}`}>
        {dateObj}
      </span>
    ) : (
      <div className={`flex w-full flex-wrap justify-center md:flex-nowrap ${props.additionalClasses ?? ''}`}>
        {objKeys.map((key, index) => (
          <span key={index}>
            <span suppressHydrationWarning className='mx-1 font-semibold md:mx-2'>
              {dateObj[key]}
            </span>
            <span className='capitalize'>{competitions.time[key]}</span>
          </span>
        ))}
        <span className='ml-1 md:ml-2'>{competitions.time_left}</span>
      </div>
    ))
  )
}
