import moment from 'moment'

const secondsInMinute = 60
const secondsInHour = 3600
const secondsInDay = secondsInHour * 24

export const Format = {
  withCommas(n: number, digit?: number) {
    if (typeof digit == 'undefined' || digit == 0) {
      return Math.round(n).toString()
    }
    let result = Number(n).toString()
    const arr = result.split('.')

    if (arr.length < 2) {
      result += '.'
      for (let i = 0; i < digit; i += 1) {
        result += '0'
      }
      return result
    }
    const integer = arr[0]
    const decimal = arr[1]
    if (decimal.length == digit) {
      return result
    }
    if (decimal.length < digit) {
      for (let i = 0; i < digit - decimal.length; i += 1) {
        result += '0'
      }
      return result
    }
    return integer + '.' + decimal.toString().substring(0, digit)
  },
  withCurrency(n: number, currencyCode?: string, digit?: number) {
    return this.getCurrencySymbol(currencyCode) + this.withCommas(n, digit ?? 2)
  },
  getDecimal(n: number) {
    if (!n) return
    const number = Number(n).toFixed(4).toString()
    return `.${number.split('.')[1].substring(0, 2)}`
  },
  getNoDecimal(n: number) {
    if (!n) return
    return Number(n).toString().split('.')[0]
  },
  capitalize(str: string) {
    const arr = str.split(' ')
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1)
    }
    return arr.join(' ')
  },
  hourMinuteSecond(totalSeconds: number, withString?: boolean, timeLang?: Record<string, string>) {
    const hours = Math.floor(totalSeconds / secondsInHour)
    const remainder = totalSeconds - hours * secondsInHour
    const minutes = Math.floor(remainder / secondsInMinute)
    const seconds = Math.floor(remainder - minutes * secondsInMinute)
    const withStringFormat = minutes > 0 ? this.getDateTimeStr(null, hours, minutes, null, timeLang) : this.getDateTimeStr(null, hours, minutes, seconds, timeLang)
    return withString ? withStringFormat : { hours, minutes, seconds }
  },
  hourMinuteSecondWithoutSuffix(totalSeconds: number) {
    const hours = Math.floor(totalSeconds / secondsInHour)
    const remainder = totalSeconds - hours * secondsInHour
    const minutes = Math.floor(remainder / secondsInMinute)
    const seconds = Math.floor(remainder - minutes * secondsInMinute)
    return `${this.pad(hours, 2)}:${this.pad(minutes, 2)}:${this.pad(seconds, 2)}`
  },
  minuteSecondWithoutSuffix(totalSeconds: number) {
    const remainder = totalSeconds
    const minutes = Math.floor(remainder / secondsInMinute)
    const seconds = Math.floor(remainder - minutes * secondsInMinute)
    return `${this.pad(minutes, 2)}:${this.pad(seconds, 2)}`
  },
  minuteSecondMillis(totalSeconds: number, timeLang?: Record<string, any>) {
    // const hours = Math.floor(totalSeconds / secondsInHour)
    // const remainder = totalSeconds - hours * secondsInHour
    const minutes = Math.floor(totalSeconds / secondsInMinute)
    const remainderSeconds = totalSeconds - minutes * secondsInMinute
    const seconds = Math.floor(remainderSeconds)
    //return `${this.pad(minutes, 2)}:${this.pad(seconds, 2)}.${this.pad(millis, 3)}`
    return `${this.pad(minutes, 2)} ${this.capitalize(timeLang?.minutes)} ${this.pad(seconds, 2)} ${this.capitalize(timeLang?.seconds)}`
  },
  // todo: test this
  dayHourMinute(totalSeconds: number, withString?: boolean, timeLang?: Record<string, string>) {
    const days = Math.floor(totalSeconds / secondsInDay)
    let remainder = totalSeconds - days * secondsInDay
    const hours = Math.floor(remainder / secondsInHour)
    remainder = remainder - hours * secondsInHour
    const minutes = Math.floor(remainder / secondsInMinute)
    const seconds = Math.floor(remainder - minutes * secondsInMinute)
    const withStringFormat = hours > 0 ? this.getDateTimeStr(days, hours, null, null, timeLang) : this.getDateTimeStr(days, hours, minutes, null, timeLang)
    return withString ? withStringFormat : { days, hours, minutes, seconds }
  },

  getDateTimeStr(day?: number, hour?: number, min?: number, second?: number, timeLang?: Record<string, string>) {
    let result = ''
    if (!timeLang) return result
    if (day) {
      result = day.toString() + (day > 1 ? ` ${timeLang?.days} ` : ` ${timeLang?.day} `)
    }
    if (hour) {
      result += hour.toString() + (hour > 1 ? ` ${timeLang?.hours} ` : ` ${timeLang?.hour} `)
    }
    if (min) {
      result += min.toString() + (min > 1 ? ` ${timeLang?.minutes} ` : ` ${timeLang?.minute} `)
    }
    if (second) {
      result += second.toString() + (second > 1 ? ` ${timeLang?.seconds}` : ` ${timeLang?.second}`)
    }
    return this.capitalize(result)
  },

  getUnitString(n: number, originStr: string) {
    return n > 1 ? originStr + 's' : originStr
  },
  dayHourMinuteIfMoreThanOneDay(totalSeconds: number, withString?: boolean, timeLang?: Record<string, string>) {
    const hours = totalSeconds / secondsInHour
    return hours > 24
      ? this.dayHourMinute(totalSeconds, withString, timeLang)
      : hours < 1 && withString
      ? this.minuteSecondMillis(totalSeconds, timeLang)
      : this.hourMinuteSecond(totalSeconds, withString, timeLang)
  },
  pad(n: number, width: number): string {
    const prefix = '0'
    const str = n + ''
    return str.length >= width ? str : new Array(width - str.length + 1).join(prefix) + str
  },

  dateFormat(dateStr: string) {
    return moment.utc(dateStr).local().format('DD/MM/YYYY')
  },
  dateHourMinuteFormat(dateStr: string) {
    return moment.utc(dateStr).local().format('DD/MM/YYYY hh:mm a')
  },
  dateMonthYearFormat(dateStr: string) {
    return moment.utc(dateStr).local().format('Do MMMM yyyy')
  },
  getCurrencySymbol(currencyCode?: string) {
    switch (currencyCode) {
      case 'GBP':
        return '£'
      case 'MXN':
        return '$'
      default:
        return '$'
    }
  },
  endTimeFormat(endTime: string) {
    if (!endTime) {
      return ''
    }
    const now = moment()
    const endMoment = moment.utc(endTime).local()
    const endMomentMMDD = endMoment.format('MMDD')
    const hour = endMoment.hours()
    // const minutes = endMoment.minutes()

    let timeStr = 'Draw '
    let formatType = 'Do MMM '

    if (now.format('MMDD') == endMomentMMDD) {
      timeStr += ' Today '
      formatType = ''
    } else if (now.add(1, 'day').format('MMDD') == endMomentMMDD) {
      timeStr += ' Tomorrow '
      formatType = ''
    }
    if (hour == 0) {
      timeStr = 'Expected ' + timeStr
    } else {
      formatType += 'hA'
    }
    return timeStr + (formatType ? endMoment.format(formatType) : '')
  },

  isZero(endTime: string) {
    if (!endTime) {
      return false
    }
    return endTime.includes(':11:00')
  },
}

export function formatAsOrdinal(n: number) {
  const j = n % 10
  const k = n % 100
  if (j === 1 && k !== 11) {
    return n + 'st'
  }
  if (j === 2 && k !== 12) {
    return n + 'nd'
  }
  if (j === 3 && k !== 13) {
    return n + 'rd'
  }
  return n + 'th'
}

Number.prototype.toFixed = function (n) {
  if (n > 20 || n < 0) {
    throw new RangeError('toFixed() digits argument must be between 0 and 20')
  }

  const number = this
  if (isNaN(number) || number >= Math.pow(10, 21)) {
    return number.toString()
  }

  if (typeof n == 'undefined' || n == 0) {
    return Math.round(number).toString()
  }

  let result = number.toString()
  const arr = result.split('.')
  // 整数的情况
  if (arr.length < 2) {
    result += '.'
    for (let i = 0; i < n; i += 1) {
      result += '0'
    }
    return result
  }

  const integer = arr[0]
  const decimal = arr[1]

  if (decimal.length == n) {
    return result
  }

  if (decimal.length < n) {
    for (let i = 0; i < n - decimal.length; i += 1) {
      result += '0'
    }
    return result
  }
  result = integer + '.' + decimal.substr(0, n)
  const last = decimal.substr(n, 1)
  // 四舍五入，转换为整数再处理，避免浮点数精度的损失
  if (parseInt(last, 10) >= 5) {
    const x = Math.pow(10, n)
    result = (Math.round(parseFloat(result) * x) + 1) / x
    result = result.toFixed(n)
  }
  return result
}
