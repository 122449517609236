import { useRef, useEffect, useState } from 'react'
import { CompetitionDetail } from '@models/Index'
import { API_URL } from '@/utils/Consts'
import axios from 'lib/axios'
import { Action0 } from '@/utils/Types'
import { useCompetitionShortNameContext, useLocaleContext, useShowCartContext, useShowMexplayModalContext } from './Contexts'

export function useMounted() {
  const isMounted = useRef(false)
  useEffect(() => {
    isMounted.current = true
    return () => {
      isMounted.current = false
    }
  }, [])
  return isMounted
}

export const useRenderCounter = label => {
  const counter = useRef(0)
  counter.current++
  console.log(`RENDER COUNTER: ${label} rendered ${counter.current} times`)
}

export function useScrollable(scrollable: boolean) {
  useEffect(() => {
    if (typeof scrollable !== 'undefined') {
      if (scrollable) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'visible'
      }
    }

    return () => {
      document.body.style.overflow = 'visible'
    }
  }, [scrollable])
}

export function useModalCompetition(toggleShowMexplayModal?: Action0) {
  const { shortName, setShortName } = useCompetitionShortNameContext()
  const [isLoading, setIsLoading] = useState(false)
  const [competitionData, setCompetitionData] = useState<CompetitionDetail>(null)
  const [showCompetitionData, setShowCompetitionData] = useState<boolean>(false)
  const { showMexplayModal } = useShowMexplayModalContext()
  const { locale } = useLocaleContext()
  const checkout = () => {
    setShowCompetitionData(false)
    setShortName('')
  }
  const toggleShowCompetitionData = () => {
    setShowCompetitionData(prev => !prev)
    setShortName('')
    toggleShowMexplayModal()
  }
  useEffect(() => {
    if (!shortName) {
      setShowCompetitionData(false)
      return
    }
    const getCompetitionDetails = async shortName => {
      setIsLoading(true)
      setShowCompetitionData(false)
      const res = await axios.get(API_URL.competitionDetail + '?store_id=2&short_name=' + shortName, {
        headers: {
          'Accept-Language': locale,
        },
      })
      const data: CompetitionDetail = res.data.data
      setCompetitionData(data)
      setShowCompetitionData(true)
      setIsLoading(false)
      if (!showMexplayModal) toggleShowMexplayModal()
    }

    getCompetitionDetails(shortName)
  }, [shortName])

  return {
    isLoading,
    competitionData,
    showCompetitionData,
    toggleShowCompetitionData,
    checkout,
  }
}

export function useModalCart(toggleShowMexplayModal?: Action0) {
  const { showCart, setShowCart } = useShowCartContext()
  const { shortName, setShortName } = useCompetitionShortNameContext()
  const toggleShowCart = () => {
    if (shortName) {
      setShortName('')
    } else {
      toggleShowMexplayModal()
    }

    setShowCart(prev => !prev)
  }

  return {
    showCart,
    toggleShowCart,
  }
}

export function useModalMexplay() {
  const { showMexplayModal, setShowMexplayModal } = useShowMexplayModalContext()
  const toggleShowMexplayModal = () => {
    setShowMexplayModal(prev => !prev)
  }

  return {
    showMexplayModal,
    toggleShowMexplayModal,
  }
}

export default function useWindowDimensions() {
  const getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
    }
  }
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowDimensions
}