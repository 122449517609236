import { useLocaleContext } from '@/hooks/Contexts'
import { API_URL } from '@/utils/Consts'
import { BannerItem } from '@models/Index'
import axios from 'axios'
import md5 from 'js-md5'
import { appUtils } from 'lib/AppUtils'
import { useRouter } from 'next/router'
import { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick-theme.css'
import 'slick-carousel/slick/slick.css'
import { ImgFallback } from '../Image'

export function BannerSlider() {
  const [shownBanners, setShownBanners] = useState<BannerItem[]>([])
  const router = useRouter()
  const { locale } = useLocaleContext()
  const [showVid, setShowVid] = useState(true)
  const mexplayAuth = () => {
    const key = process.env.NEXT_PUBLIC_MEXPLAY_KEY
    const time = Math.round(+new Date() / 1000)
      .toString()
      .substring(0, 7)

    return md5(`${time}${key}`)
  }
  useEffect(() => {
    const fetchBanners = async () => {
      if (typeof window !== undefined) {
        const formData = new FormData()
        formData.append('type', 'get_promotion_list')
        formData.append('auth', mexplayAuth())
        const url = locale === 'es' ? API_URL.esMexplayBanners : API_URL.enMexplayBanners
        await axios
          .post(url, formData, {
            headers: {
              Authorization: 'Bearer ' + mexplayAuth(),
            },
          })
          .then(res => {
            const banners = res.data
            if (banners && banners.info && banners.info.length > 0 && banners.status === 1) {
              setShownBanners(banners.info)
            }
          })
      }
    }
    if (!router.isReady || !locale) return
    fetchBanners()
  }, [router.isReady, locale])

  const ref = useRef(null)

  useEffect(() => {
    if (ref?.current && 'networkState' in ref?.current) {
      if (ref?.current?.networkState === 3) {
        setShowVid(false)
      } else {
        setShowVid(true)
      }
    }
  }, [ref?.current])
  return (
    <div className='bg-banner-surface'>
      <div className='main-container banner-height flex-col !items-end py-2 lg:flex-row lg:py-4'>
        <ImgFallback src='/assets/mexplay-banner.png' className='h-full w-full rounded-20 lg:max-h-60 lg:w-2/5' />
        <div className='flex h-full w-full space-x-2 pt-4 sm:pt-5 md:space-x-4 lg:max-h-60 lg:w-3/5 lg:pt-0'>
          <div className='relative h-full w-1/3 place-self-center rounded-20'>
            <ImgFallback src={`https://www.u2d8899.com/mexicoimages/banner/competition_number_one_${locale}.jpg`} className='sub-slider-height rounded-20' />
            <img
              className='absolute left-0 right-0 -top-[21%] h-6 xs:-top-[16%] sm:-top-[9%] sm:h-8 md:-top-[14%] md:h-10 lg:-top-[17%] lg:h-12'
              src='/assets/top-1.png'
              style={{ margin: '0 auto' }}
            />
          </div>
          <div className='h-full w-2/3'>
            {showVid ? (
              <video
                ref={ref}
                width='100%'
                height='100%'
                className='sub-slider-height pointer-events-none rounded-20 object-fill'
                playsInline
                autoPlay
                muted
                loop
              >
                <source src='/assets/competition-banners.mp4' type='video/mp4' />
              </video>
            ) : (
              <>
                {!appUtils.isEmpty(shownBanners) && (
                  <>
                    {shownBanners && (
                      <Slider infinite slidesToShow={1} autoplay arrows={false} className='banner-slider oveflow-hidden h-full'>
                        {shownBanners.map((item, index) => {
                          return (
                            <a
                              key={`banner-item-${index}`}
                              href={`${process.env.NEXT_PUBLIC_MEXPLAY_URL}promotions/${item.id}`}
                              className='h-full'
                              target='_parent'
                            >
                              <img src={item.bannerurl} className={`sub-slider-height cursor-pointer rounded-20`} />
                            </a>
                          )
                        })}
                      </Slider>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}
