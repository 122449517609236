import { HomeStats, User } from '@models/Index'
import { CartCount } from '@models/Response'
import { createContext, useContext } from 'react'
import english from '../public/lang/en.json'

export const mobileLayout = {
  isDesktop: false,
  margin: {
    horizontal: 12,
    vertical: 12,
  },
}

export type Layout = typeof mobileLayout

export const desktopLayout: Layout = {
  isDesktop: true,
  margin: {
    horizontal: 16,
    vertical: 16,
  },
}

export const LayoutContext = createContext(mobileLayout)

export function useLayout() {
  return useContext(LayoutContext)
}

/**
 * Language Context
 *
 * Default: english
 */
export type Strings = typeof english

export const LanguageContext = createContext(english)

export function useLanguage() {
  return useContext(LanguageContext)
}

export const UserContext = createContext(null as null | User)

export function useUser() {
  return useContext(UserContext)
}

export const defaultSystemConfig = {
  isSafari: true,
  isMaintain: false,
  screen: {
    width: 1200,
    height: 1600,
  },
}

export const SystemConfigContext = createContext(defaultSystemConfig)

export function useSystemConfig() {
  return useContext(SystemConfigContext)
}

export const defaultStats = {
  home: null as HomeStats,
  // payment: null as PaymentGateway,
  topup: null as number,
  cash_back: null as number,
}

export const StatsContext = createContext(defaultStats)

export function useStats() {
  return useContext(StatsContext)
}

export const MyCartContext = createContext(null as null | CartCount)

export function useMyCart() {
  return useContext(MyCartContext)
}

export const CompetitionShortNameContext = createContext(null)
export function useCompetitionShortNameContext() {
  return useContext(CompetitionShortNameContext)
}
export const ShowMexplayModalContext = createContext(null)
export function useShowMexplayModalContext() {
  return useContext(ShowMexplayModalContext)
}

export const ShowCartContext = createContext(null)
export function useShowCartContext() {
  return useContext(ShowCartContext)
}

export const LocaleContext = createContext(null)
export function useLocaleContext() {
  return useContext(LocaleContext)
}