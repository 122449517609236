import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { icons } from '@/utils/Images'
export function ImageFallback({ src, fallbackSrc = null, ...rest }) {
  return <ImgFallback src={src} fallbackSrc={fallbackSrc} {...rest} />
}

export function ImgFallback({ src, fallbackSrc = null, ...rest }) {
  const [imgSrc, setImgSrc] = useState(src)

  useEffect(() => {
    setImgSrc(src)
  }, [src])

  return (
    <img
      {...rest}
      src={imgSrc}
      onError={() => {
        setImgSrc(fallbackSrc ?? icons.noImage.src)
      }}
    />
  )
}

ImageFallback.propTypes = {
  src: PropTypes.string,
  fallbackSrc: PropTypes.string,
  rest: PropTypes.any,
}
