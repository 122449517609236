export const API_URL = {
  login: '/user/login',
  register: '/user/register',
  forgetPass: '/user/forget-pass',
  resetPass: '/user/reset-valid',
  changePass: '/api/user/reset-pass',
  verifyEmail: '/user/verify-email',
  sendEmailVerify: '/api/user/email-verify',
  logout: '/user/logout',
  getUserInfo: '/api/user/info',
  postUserInfo: '/api/user/info',
  getUserAddresses: '/api/user/addresses',
  postUserAddress: '/api/user/address',
  removeUserAddress: '/api/user/address-remove',
  getUserOrders: '/api/user/orders',
  getUserBalance: '/api/user/balance',
  getUserTransactions: '/api/user/balance-transactions',
  socialLogin: '/services/login/socialite/',
  category: '/home/category-competitions',
  competitionDetail: '/competitions/competition-details',
  recommend: '/competitions/recommend',
  banners: '/home/banners?store_id=2',
  subscribe: '/home/subscribe',
  stats: '/home/stats',
  winners: '/winners/list',
  draws: '/draw-list',
  entries: '/entry-list',
  entryDetails: '/entry-details',
  cartCount: '/cart/count?store_id=2',
  addToCart: '/cart/add-to-cart?store_id=2',
  cartDetail: '/cart/details',
  placeOrder: '/api/cart/place-order?store_id=2',
  clearCart: '/cart/clear?store_id=2',
  removeItem: '/cart/remove-item',
  updateCart: '/cart/update',
  orderDetail: '/api/order/details',
  addCoupon: '/cart/add-coupon?store_id=2',
  useBalance: '/api/cart/use-balance',
  donations: '/donations',
  topUp: '/api/user/top-up/charge',
  topUpOptions: '/api/user/top-up/options',
  topUpHistory: '/api/user/top-up/payments',
  playerAuth: '/player/auth',
  playerOrderDetail: '/player/orderDetail',
  enMexplayBanners: 'mexicoenglishpwa/ajax_check.php',
  esMexplayBanners: 'mexicopwa/ajax_check.php',
}

export const COUNTRY_LIST = [
  { value: 'United Kingdom (UK)', code: 'GB' },
  { value: 'Republic of Ireland', code: 'IE' },
  { value: 'Mexico', code: 'MX' },
]

export const IE_COUNTY_LIST = [
  { code: 'CW', value: 'Carlow' },
  { code: 'CN', value: 'Cavan' },
  { code: 'CE', value: 'Clare' },
  { code: 'CO', value: 'Cork' },
  { code: 'DL', value: 'Donegal' },
  { code: 'D', value: 'Dublin' },
  { code: 'G', value: 'Galway' },
  { code: 'KY', value: 'Kerry' },
  { code: 'KE', value: 'Kildare' },
  { code: 'KK', value: 'Kilkenny' },
  { code: 'LS', value: 'Laois' },
  { code: 'LM', value: 'Leitrim' },
  { code: 'LK', value: 'Limerick' },
  { code: 'LD', value: 'Longford' },
  { code: 'LH', value: 'Louth' },
  { code: 'MO', value: 'Mayo' },
  { code: 'MH', value: 'Meath' },
  { code: 'MN', value: 'Monaghan' },
  { code: 'OY', value: 'Offaly' },
  { code: 'RN', value: 'Roscommon' },
  { code: 'SO', value: 'Sligo' },
  { code: 'TA', value: 'Tipperary' },
  { code: 'WD', value: 'Waterford' },
  { code: 'WH', value: 'Westmeath' },
  { code: 'WX', value: 'Wexford' },
  { code: 'WW', value: 'Wicklow' },
]

export const MX_STATE_LIST = [
  { code: 'CH', value: 'Chihuahua' },
  { code: 'SN', value: 'Sonora' },
  { code: 'CA', value: 'Coahuila' },
  { code: 'DO', value: 'Durango' },
  { code: 'OA', value: 'Oaxaca' },
  { code: 'TS', value: 'Tamaulipas' },
  { code: 'JO', value: 'Jalisco' },
  { code: 'ZS', value: 'Zacatecas' },
  { code: 'BA', value: 'Baja California Sur' },
  { code: 'CS', value: 'Chiapas' },
  { code: 'VZ', value: 'Veracruz' },
  { code: 'BC', value: 'Baja California' },
  { code: 'NL', value: 'Nuevo León' },
  { code: 'GO', value: 'Guerrero' },
  { code: 'SA', value: 'San Luis Potosí' },
  { code: 'MN', value: 'Michoacán' },
  { code: 'SI', value: 'Sinaloa' },
  { code: 'CM', value: 'Campeche' },
  { code: 'QR', value: 'Quintana Roo' },
  { code: 'YN', value: 'Yucatán' },
  { code: 'PU', value: 'Puebla' },
  { code: 'GU', value: 'Guanajuato' },
  { code: 'NY', value: 'Nayarit' },
  { code: 'TB', value: 'Tabasco' },
  { code: 'MX', value: 'México' },
  { code: 'HI', value: 'Hidalgo' },
  { code: 'QU', value: 'Querétaro' },
  { code: 'CO', value: 'Colima' },
  { code: 'AG', value: 'Aguascalientes' },
  { code: 'MO', value: 'Morelos' },
  { code: 'TL', value: 'Tlaxcala' },
  { code: 'MC', value: 'México City' },
]

export type postMessageType = 'loginNeed' | 'depositNeed'

export const PERMIT_NO = [
  'Producciones Móviles S.A. de C.V.: DGAJS/SCEVF/P-06/2005-TER',
  'UnoCapali la Paz Operaciones S.A. de C.V.: DGJS/1580/2021, DGJS/DCRCA/2772/2021'
]