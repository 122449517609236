import Axios from 'axios'
const axios = Axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_HOST_URL,
  headers: {
    'Cache-Control': 'no-cache',
    'X-Requested-With': 'XMLHttpRequest',
    'accept': 'application/json',
    'Content-Type': 'application/json',
    'Accept-Language': 'en',
  },
  withCredentials: true,
})
export default axios
