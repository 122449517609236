import { ChangeEventHandler, FC } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { ExpandableBlock, ExpandableBlockProps, TextInput, SearchBarDropdown } from './FormElementV2'
import validator from 'validator'
import { useLanguage } from '@/hooks/Contexts'
import { MX_STATE_LIST } from '@/utils/Consts'
import { Action1 } from '@/utils/Types'

interface AddressProps extends ExpandableBlockProps {
  onChange?: ChangeEventHandler<HTMLInputElement>
  toggleDropDownOpen?: Action1<boolean>
  form: UseFormReturn
}

const AddressLayout: FC<AddressProps> = ({ onChange, form, title, openDefault, toggleDropDownOpen, children }) => {
  const { user: userStr, errors: errStr } = useLanguage()
  const {
    register,
    formState: { errors },
    setValue,
  } = form

  const defaultClass = 'grid grid-cols-1 items-center gap-y-6 md:gap-y-0 md:gap-x-4 md:grid-cols-2 md:items-start'

  return (
    <ExpandableBlock title={title} openDefault={openDefault}>
      <div className={defaultClass + ' mt-4'}>
        <TextInput
          label={userStr.firstName}
          required={true}
          input={{
            ...register('address.first_name', {
              required: userStr.firstName + errStr.required,
              validate: value => {
                if (value) return validator.isAlpha(value.replace(' ', '')) || errStr.name_invalid
              },
              onChange,
            }),
          }}
          showValidated={Boolean(errors && errors.address && errors.address['first_name'])}
          valid={Boolean(!(errors && errors.address && errors.address['first_name']))}
          tipMessage={(errors && errors.address && errors.address['first_name'] && errors.address['first_name']['message']) ?? null}
        />
        <TextInput
          label={userStr.lastName}
          required={true}
          input={{
            ...register('address.last_name', {
              required: userStr.lastName + errStr.required,
              validate: value => {
                if (value) return validator.isAlpha(value.replace(' ', '')) || errStr.name_invalid
              },
              onChange,
            }),
          }}
          showValidated={Boolean(errors && errors.address && errors.address['last_name'])}
          valid={Boolean(!(errors && errors.address && errors.address['last_name']))}
          tipMessage={(errors && errors.address && errors.address['last_name'] && errors.address['last_name']['message']) ?? null}
        />
      </div>
      <div className={defaultClass + ' mt-6'}>
        <TextInput
          label={userStr.email}
          required={true}
          input={{
            ...register('address.email', {
              required: userStr.email + errStr.required,
              validate: value => {
                if (value) return validator.isEmail(value) || errStr.email_invalid
              },
              onChange,
            }),
          }}
          showValidated={Boolean(errors && errors.address && errors.address['email'])}
          valid={Boolean(!(errors && errors.address && errors.address['email']))}
          tipMessage={(errors && errors.address && errors.address['email'] && errors.address['email']['message']) ?? null}
        />
        <TextInput
          label={userStr.phoneNum}
          required={true}
          input={{
            ...register('address.phone', {
              required: userStr.phoneNum + errStr.required,
              onChange,
            }),
          }}
          showValidated={Boolean(errors && errors.address && errors.address['phone'])}
          valid={Boolean(!(errors && errors.address && errors.address['phone']))}
          tipMessage={(errors && errors.address && errors.address['phone'] && errors.address['phone']['message']) ?? null}
        />
      </div>
      <TextInput
        label={userStr.street}
        additionalClasses='mt-6'
        required={true}
        input={{
          ...register('address.street', {
            required: userStr.street + errStr.required,
            onChange,
          }),
        }}
        showValidated={Boolean(errors && errors.address && errors.address['street'])}
        valid={Boolean(!(errors && errors.address && errors.address['street']))}
        tipMessage={(errors && errors.address && errors.address['street'] && errors.address['street']['message']) ?? null}
      />

      <div className={defaultClass + ' mt-6'}>
        <TextInput
          label={userStr.city}
          required={true}
          input={{
            ...register('address.city', {
              required: userStr.city + errStr.required,
              onChange,
            }),
          }}
          showValidated={Boolean(errors && errors.address && errors.address['city'])}
          valid={Boolean(!(errors && errors.address && errors.address['city']))}
          tipMessage={(errors && errors.address && errors.address['city'] && errors.address['city']['message']) ?? null}
        />
        <SearchBarDropdown
          searchable={false}
          closeAnimate={false}
          label={userStr.state}
          required
          defOptions={MX_STATE_LIST.map(state => {
            return state.value
          })}
          input={{
            type: 'text',
            placeholder: userStr.state + errStr.required,
            ...register('address.county', {
              required: userStr.state + errStr.required,
            }),
            className: 'w-full cursor-pointer',
          }}
          onSelect={v => {
            setValue('address.county', v, { shouldValidate: true, shouldDirty: true })
          }}
          showValidated={Boolean(errors && errors.address && errors.address['state'])}
          valid={Boolean(!(errors && errors.address && errors.address['state']))}
          tipMessage={(errors && errors.address && errors.address['state'] && errors.address['state']['message']) ?? null}
          toggleDropDownOpen={toggleDropDownOpen}
          listContainerClass='md:max-h-36 lg:max-h-40 xl:max-h-42'
        />
      </div>

      <div className={defaultClass + ' mt-6'}>
        <TextInput
          label={userStr.postcode}
          required={true}
          input={{
            ...register('address.postcode', {
              required: userStr.postcode + errStr.required,
              onChange,
            }),
          }}
          showValidated={Boolean(errors && errors.address && errors.address['postcode'])}
          valid={Boolean(!(errors && errors.address && errors.address['postcode']))}
          tipMessage={(errors && errors.address && errors.address['postcode'] && errors.address['postcode']['message']) ?? null}
        />
        <TextInput
          label='country'
          additionalClasses='mt-1 hidden'
          input={{
            placeholder: userStr.country,
            ...register('address.country', {
              onChange,
            }),
            value: 'Mexico',
          }}
        />
      </div>
      {children}
    </ExpandableBlock>
  )
}

export default AddressLayout
